/* eslint-disable flowtype/no-weak-types */
// @flow

import React, { type Element, useEffect, useState } from 'react';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import AccordionItem from '@design-system/component-library/src/components/Accordion/AccordionItem';
import Accordion from '@design-system/component-library/src/components/Accordion';
import { useTranslation } from 'react-i18next';
import axios, { $AxiosXHR, CancelToken } from 'axios';
import uuidv4 from 'uuid/v4';
import HTTP from 'http-status-codes';
import { useSelector } from 'react-redux';
import type { Canceler } from 'axios';
import CenterHorizontally from '../../../components/CenterHorizontally/CenterHorizontally';
import type { CallFlowCalendarEntityT } from '../../../ducks/entities/calendar/calendarTypes';
import CalendarSection from '../components/view/children/calendar/CalendarSection/CalendarSection';
import EditCalendarForm from './EditCalendarForm';
import { enterpriseCalendarRegExp } from '../../../helpers';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../ducks/currentUser/currentUserTypes';

import styles from './CalendarContent.module.scss';

export type PropsT = {|
  isLoadingCalendar: boolean,
  calendars: CallFlowCalendarEntityT[],
  searchText: string,
  enterpriseId: string,
  updateCalendar: (*, *) => Promise<void>,
  gotoCalendarId?: string
|};

const PROCESS_CHECK_DELAY = 1000;

const CalendarContent = (props: PropsT): Element<typeof CenterHorizontally | typeof Accordion> => {
  const {
    isLoadingCalendar,
    calendars,
    searchText,
    enterpriseId,
    updateCalendar,
    gotoCalendarId
  } = props;
  const isDefaultCalendar = ownerAdmtiveDomainId => ownerAdmtiveDomainId === '0.';
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);
  const { t } = useTranslation();
  const cancelRequest = React.useRef<Canceler>();
  const [uuid, setUuid] = useState();
  const [selectedCalendarId, setSelectedCalendarId] = useState(gotoCalendarId);
  const [calendarServices, setCalendarServices] = useState([]);
  const [isLoadingServicesData, setIsLoadingServicesData] = useState(false);
  const [contentRefreshToken, setContentRefreshToken] = useState(uuidv4());
  const [isEditWeekScheduleVisible, setIsEditWeekScheduleVisible] = useState(false);

  const [bankHolidays, setBankHolidays] = useState();
  const [availableTemplates, setAvailableTemplates] = useState();

  const saveDate = async (calendarConfigId, platformCalendarId, date) => {
    const payload = date;
    try {
      const method = date.identifier ? 'PATCH' : 'POST';
      await axios({
        method,
        url: `/api/v1/enterprises/${enterpriseId}/calendarconfigs/${calendarConfigId}/dates`,
        data: payload,
        headers: createCsrfHeader(currentUser)
      });
    } catch (error) {
      console.error(error);
    }
    setContentRefreshToken(uuidv4());
  };

  const deleteDate = async (calendarConfigId, platformCalendarId, date) => {
    try {
      await axios({
        method: 'DELETE',
        url: `/api/v1/enterprises/${enterpriseId}/calendarconfigs/${calendarConfigId}/dates/${date.identifier}`,
        headers: createCsrfHeader(currentUser)
      });
    } catch (error) {
      console.error(error);
    }
    setContentRefreshToken(uuidv4());
  };

  const editWeekSchedule = () => {
    setIsEditWeekScheduleVisible(true);
  };

  const setTemplate = async (calendarConfigId, platformCalendarId, templateId) => {
    try {
      await axios({
        method: 'PATCH',
        url: `/api/v1/enterprises/${enterpriseId}/calendarconfigs/${calendarConfigId}`,
        data: { calendarTemplateId: templateId || null },
        headers: createCsrfHeader(currentUser)
      });
    } catch (error) {
      console.error(error);
    }
    setContentRefreshToken(uuidv4());
  };

  const calendarDetailsView = (cal, removeEditButton) => (
    <>
      <CalendarSection
        calendar={cal}
        enterpriseId={enterpriseId}
        calendarServices={calendarServices}
        bankHolidays={bankHolidays}
        isLoadingServicesData={isLoadingServicesData}
        saveDateFn={saveDate}
        deleteDateFn={deleteDate}
        setTemplateFn={setTemplate}
        editWeekScheduleFn={editWeekSchedule}
        updateCalendarFn={updateCalendar}
        availableTemplates={availableTemplates}
        refreshToken={contentRefreshToken}
        editable={!removeEditButton}
      />
      {isEditWeekScheduleVisible && !removeEditButton && (
        <div
          className="ea-modal ea-modal--open styleguide-dialog-position"
          role="dialog"
          aria-modal="true"
        >
          <div className="ea-modal__overlay" />
          <div className={`ea-modal__content ${styles.weekdayschedule} ${styles['modal-content']}`}>
            <EditCalendarForm
              calendar={cal}
              onSaveForm={async formData => {
                updateCalendar(cal, formData);
              }}
              onCancel={event => {
                if (event) {
                  event.preventDefault();
                }
                setIsEditWeekScheduleVisible(false);
              }}
            />
          </div>
        </div>
      )}
    </>
  );

  const loadBankHolidays = async () => {
    if (!bankHolidays) {
      const response = await axios.get(`/api/v1/config/bank_holidays`);
      setBankHolidays(response.data);
    }
  };

  const loadAvailableTemplates = async () => {
    if (!availableTemplates) {
      const response = await axios.get(`/api/v1/enterprises/${enterpriseId}/calendartemplates`);
      const templates = response.data;
      templates.sort((a, b) => (a.enterpriseId < b.enterpriseId ? -1 : 0));
      setAvailableTemplates(templates);
    }
  };

  const loadCalendarServices = async (calendarName: string) => {
    const response: $AxiosXHR<string> = await axios({
      method: 'POST',
      url: `/api/v1/enterprises/${enterpriseId}/calendarservices`,
      data: { calendarName },
      headers: createCsrfHeader(currentUser)
    });

    if (response && response.status === HTTP.OK) {
      setUuid(response.data);
    }
  };

  const stopCalendarSearch = () => {
    axios({
      method: 'POST',
      // $FlowFixMe: enterpriseId already null checked
      url: `/api/v1/enterprises/${enterpriseId}/calendarservices/done?uuid=${uuid}`,
      cancelToken: new CancelToken(canceler => {
        cancelRequest.current = canceler;
      }),
      headers: createCsrfHeader(currentUser)
    });
  };

  useEffect(() => {
    loadBankHolidays();
    loadAvailableTemplates();
    let id;
    if (uuid) {
      id = setInterval(async () => {
        try {
          const response = await axios({
            method: 'GET',
            // $FlowFixMe: enterpriseId already null checked
            url: `/api/v1/enterprises/${enterpriseId}/calendarservices/process?uuid=${uuid}`,
            cancelToken: new CancelToken(canceler => {
              cancelRequest.current = canceler;
            })
          });
          if (response) {
            if (response.data.searched === response.data.toBeSearched) {
              setCalendarServices(response.data.calendarServiceItems);
              setIsLoadingServicesData(false);
              clearInterval(id);
              stopCalendarSearch();
            }
          }
        } catch (e) {
          console.log(e);
        }
      }, PROCESS_CHECK_DELAY);
    }
    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  return isLoadingCalendar ? (
    <CenterHorizontally>
      <LoadingSpinner />
    </CenterHorizontally>
  ) : (
    <Accordion className={styles.header}>
      {calendars &&
        calendars
          .sort((a, b) =>
            a.name
              .replace(enterpriseCalendarRegExp, '')
              .localeCompare(b.name.replace(enterpriseCalendarRegExp, ''))
          )
          .filter(
            cal =>
              !searchText ||
              cal.name
                .replace(enterpriseCalendarRegExp, '')
                .toUpperCase()
                .includes(searchText.toUpperCase())
          )
          .map(
            cal =>
              cal && (
                <AccordionItem
                  defaultOpen={gotoCalendarId === cal.id}
                  id={`calendar-${cal.id}`}
                  key={`calendar-${cal.id}`}
                  heading={`${cal.name.replace(enterpriseCalendarRegExp, '')} 
                                ${
                                  isDefaultCalendar(cal.ownerAdmtiveDomainId)
                                    ? t('calendars.cannotEditTitle')
                                    : ''
                                }`}
                  onOpen={() => {
                    setSelectedCalendarId(cal.id);
                    setIsLoadingServicesData(true);
                    loadCalendarServices(cal.name);
                  }}
                >
                  {selectedCalendarId === cal.id && (
                    <div className={styles['calendar-row']}>
                      {isDefaultCalendar(cal.ownerAdmtiveDomainId) && (
                        <div className={styles['info-row']}>{t('calendars.cannotEditInfo')}</div>
                      )}
                      {calendarDetailsView(cal, isDefaultCalendar(cal.ownerAdmtiveDomainId))}
                    </div>
                  )}
                </AccordionItem>
              )
          )}
    </Accordion>
  );
};

export default CalendarContent;
