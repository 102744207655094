// @flow

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '@design-system/component-library/src/components/Input';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import Checkbox, { CheckboxGroup } from '@design-system/component-library/src/components/Checkbox';
import Toggle from '@design-system/component-library/src/components/Toggle';
import uuid from 'uuid/v4';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import axios, { CancelToken } from 'axios';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import { RadioV2 } from '@design-system/component-library';
import moment from 'moment';
import ActionButton from '../../components/Button/ActionButton';
import { isIntProd, isProd, isTest } from '../../helpers';
import DeleteButton from '../../components/Button/DeleteButton';
import configOperations from '../../ducks/config/configOperations';
import { actions as notificationActions } from '../../ducks/ui/notification';
import { createCsrfHeader } from '../../utils/accessRightUtils';
import { createOpenChangelogAction } from '../../ducks/ui/header/headerUiActions';

import styles from './BulletinEditor.module.scss';

let cancelResellersRequest;

const BulletinEditor = () => {
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.currentUser);
  const dispatch = useDispatch();
  const platformOptions =
    isProd() || isIntProd() || isTest()
      ? [
          { label: 'mob1, mob2, mob3', value: 'mob1, mob2, mob3' },
          { label: 'mob1', value: 'mob1' },
          { label: 'mob2', value: 'mob2' },
          { label: 'mob3', value: 'mob3' },
          { label: 'mob1ee', value: 'mob1ee' }
        ]
      : [
          { label: 'lab1, lab2, lab3', value: 'lab1, lab2, lab3' },
          { label: 'lab1', value: 'lab1' },
          { label: 'lab2', value: 'lab2' },
          { label: 'lab3', value: 'lab3' },
          { label: 'lab2ee', value: 'lab2ee' }
        ];
  const [currentBulletin, setCurrentBulletin] = useState();
  const [selectedBulletinVersion, setSelectedBulletinVersion] = useState('');
  const [selectedEnvironment, setSelectedEnvironment] = useState(platformOptions[0].value);
  const [activationDate, setActivationDate] = useState(new Date().toISOString().split('T')[0]);
  const [messageFi, setMessageFi] = useState('');
  const [messageEn, setMessageEn] = useState('');
  const [messageSv, setMessageSv] = useState('');
  const [messageEt, setMessageEt] = useState('');
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileFormatError, setFileFormatError] = useState(false);
  const [priority, setPriority] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [selectedResellers, setSelectedResellers] = useState([]);
  const [resellersVisibility, setResellersVisibility] = useState('default');
  const [resellers, setResellers] = useState([]);
  const bulletins = useSelector(state => state.config.bulletins);
  const reactQuillRefFi = useRef();
  const reactQuillRefEn = useRef();
  const reactQuillRefSv = useRef();
  const reactQuillRefEt = useRef();
  const [resellersKey, setResellersKey] = useState(moment().toISOString());

  const MAX_LENGTH = 2000;

  const getResellers = async environment => {
    const resellersReturned = await axios.get(`/api/v1/resellers/${environment}`, {
      cancelToken: cancelResellersRequest.token
    });
    setResellers(resellersReturned.data.results);
  };

  const clearBulletinData = () => {
    setMessageFi('');
    setMessageEn('');
    setMessageSv('');
    setMessageEt('');
    setActivationDate('');
    setSelectedBulletinVersion('');
    setCurrentBulletin(null);
    setPriority(false);
    setAttachments([]);
    setResellersVisibility('default');
  };

  useEffect(() => {
    dispatch(configOperations.getBulletins());
  }, [selectedBulletinVersion]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    cancelResellersRequest = CancelToken.source();
    clearBulletinData();
    getResellers(selectedEnvironment);
  }, [selectedEnvironment]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const foundBulletin = bulletins.find(b => b.id.toString() === selectedBulletinVersion);
    if (foundBulletin) {
      setCurrentBulletin(foundBulletin);
      setMessageFi(foundBulletin.messageFi);
      setMessageEn(foundBulletin.messageEn);
      setMessageSv(foundBulletin.messageSv);
      setMessageEt(foundBulletin.messageEt);
      setPriority(
        foundBulletin.priorityForEnvironments && foundBulletin.priorityForEnvironments.length > 0
      );
      setActivationDate(foundBulletin.activationDate?.split('T')[0] || '');
      setAttachments(foundBulletin.attachments || []);
      setSelectedResellers(foundBulletin.resellerIds || []);
      setResellersVisibility(foundBulletin.resellersVisibility || 'default');
      setResellersKey(moment().toISOString());
    } else {
      clearBulletinData();
    }
  }, [selectedBulletinVersion]); // eslint-disable-line react-hooks/exhaustive-deps

  const previewBulletin = () => {
    dispatch(createOpenChangelogAction(null, messageFi, messageEn, messageSv, messageEt));
  };

  const createBulletin = async () => {
    const newBulletin = {
      environment: selectedEnvironment,
      messageFi: '',
      messageEn: '',
      messageSv: '',
      messageEt: '',
      activationDate: '',
      resellerIds: [],
      priority: false,
      resellersVisibility: 'default'
    };
    const returnedBulletin = await dispatch(
      configOperations.createBulletin(newBulletin, createCsrfHeader(currentUser))
    );
    if (returnedBulletin) {
      setCurrentBulletin(returnedBulletin);
      setSelectedBulletinVersion(returnedBulletin.id.toString());
      dispatch(
        notificationActions.createCreateNotificationAction({
          tag: 'bulletin-create-success',
          duration: 15000,
          type: 'info',
          message: t('adminui.createSuccess')
        })
      );
    } else {
      dispatch(
        notificationActions.createCreateNotificationAction({
          tag: 'bulletin-create-failure',
          duration: 15000,
          type: 'error',
          message: t('adminui.createFailure')
        })
      );
    }
  };

  const saveBulletin = async () => {
    if (currentBulletin) {
      const updatedBulletin = {
        ...currentBulletin,
        messageFi,
        messageEn,
        messageSv,
        messageEt,
        activationDate,
        resellersVisibility,
        priority,
        resellerIds: selectedResellers
      };
      const returnedBulletin = await dispatch(
        configOperations.updateBulletin(
          currentBulletin.id,
          updatedBulletin,
          createCsrfHeader(currentUser)
        )
      );
      let attachmentsUploadedSuccessfully = true;

      if (returnedBulletin && attachments.length > 0) {
        attachmentsUploadedSuccessfully = await Promise.all(
          attachments
            .filter(attachment => attachment.attachmentId)
            .map(async attachment => {
              return configOperations.uploadBulletinAttachment(
                currentBulletin.id,
                attachment,
                createCsrfHeader(currentUser)
              );
            })
        ).then(results => results.every(result => result));
      }

      if (returnedBulletin && attachmentsUploadedSuccessfully) {
        dispatch(
          notificationActions.createCreateNotificationAction({
            tag: 'bulletin-update-success',
            duration: 15000,
            type: 'info',
            message: t('adminui.saveSuccess')
          })
        );
      } else {
        dispatch(
          notificationActions.createCreateNotificationAction({
            tag: 'bulletin-update-failure',
            duration: 15000,
            type: 'error',
            message: t('adminui.saveFailure')
          })
        );
      }
    }
  };

  const deleteBulletin = async () => {
    if (currentBulletin) {
      const returned = await dispatch(
        configOperations.deleteBulletin(currentBulletin.id, createCsrfHeader(currentUser))
      );
      if (returned) {
        clearBulletinData();
        dispatch(
          notificationActions.createCreateNotificationAction({
            tag: 'bulletin-delete-success',
            duration: 15000,
            type: 'info',
            message: t('adminui.deleteSuccess')
          })
        );
      } else {
        dispatch(
          notificationActions.createCreateNotificationAction({
            tag: 'bulletin-delete-failure',
            duration: 15000,
            type: 'error',
            message: t('adminui.deleteFailure')
          })
        );
      }
    }
  };

  const checkCharacterCount = (event, ref) => {
    if (
      ref.current &&
      ref.current.getEditor().getLength() > MAX_LENGTH &&
      event.key !== 'Backspace'
    ) {
      event.preventDefault();
    }
  };

  const areTextEditorsFilled = () => {
    const cleanMessage = msg => (msg ? msg.replaceAll('<p><br></p>', '') : '');
    return (
      cleanMessage(messageFi) &&
      cleanMessage(messageEn) &&
      cleanMessage(messageSv) &&
      cleanMessage(messageEt)
    );
  };

  const isAttachmentWrongFormat = files => {
    const acceptedFormats = [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.ms-powerpoint'
    ];
    return !acceptedFormats.includes(files[0].type);
  };

  const isAttachmentTooLarge = files => files[0].size > 10000000;

  const uploadAttachment = files => {
    if (isAttachmentTooLarge(files)) {
      setFileSizeError(true);
      return;
    }
    if (isAttachmentWrongFormat(files)) {
      setFileFormatError(true);
      return;
    }
    setFileSizeError(false);
    setFileFormatError(false);
    const url = URL.createObjectURL(files[0]);
    setAttachments([...attachments, { id: null, name: files[0].name, url, attachmentId: uuid() }]);
  };

  const removeAttachment = async (attachmentId, removeFromDatabase) => {
    if (currentBulletin && attachmentId) {
      if (removeFromDatabase) {
        await configOperations.deleteBulletinAttachment(
          currentBulletin.id,
          attachmentId,
          createCsrfHeader(currentUser)
        );
      }
      setAttachments(
        attachments.filter(
          attachment => attachment.attachmentId !== attachmentId && attachment.id !== attachmentId
        )
      );
    }
  };

  const bulletinsToEdit = [
    { value: '-', label: '' },
    ...bulletins
      .filter(b => b.environment === selectedEnvironment)
      .map(b => ({ label: b.id.toString(), value: b.id.toString() }))
  ];

  return (
    <div>
      <div className={!currentBulletin ? styles['upper-area'] : ''}>
        <div className={styles['selector-area']}>
          <Dropdown
            id="environment-selector"
            items={platformOptions}
            selectedValue={selectedEnvironment}
            onValueChange={element => setSelectedEnvironment(element.dataset.value)}
            label={t('adminui.environmentTitle')}
          />
          <Dropdown
            id="bulletin-selector"
            items={bulletinsToEdit}
            selectedValue={selectedBulletinVersion}
            style={{ paddingLeft: '15px' }}
            onValueChange={element => setSelectedBulletinVersion(element.dataset.value)}
            label={t('adminui.versionTitle')}
          />
        </div>
        <div className={styles['selector-area']}>
          <RadioGroup
            name="resellerSelect"
            value={resellersVisibility}
            onChange={setResellersVisibility}
          >
            <RadioV2 value="default" label={t('adminui.defaultResellersSelect')} />
            <RadioV2 value="only" label={t('adminui.onlyResellersSelect')} />
            <RadioV2 value="exclude" label={t('adminui.excludeResellersSelect')} />
          </RadioGroup>
          {resellersVisibility !== 'default' && (
            <CheckboxGroup name="resellers" label={t('adminui.resellersSelect')} key={resellersKey}>
              {resellers.map(reseller => (
                <Checkbox
                  key={reseller.id}
                  id={reseller.id}
                  value={reseller.id}
                  name="resellers"
                  label={reseller.domainName}
                  checked={selectedResellers.includes(reseller.id)}
                  onChange={() =>
                    setSelectedResellers(
                      selectedResellers.includes(reseller.id)
                        ? selectedResellers.filter(id => id !== reseller.id)
                        : [...selectedResellers, reseller.id]
                    )
                  }
                />
              ))}
            </CheckboxGroup>
          )}
        </div>
        <ActionButton
          id="new-bulletin-button"
          label={t('adminui.newBulletinButton')}
          onClickAction={createBulletin}
        />
      </div>
      {currentBulletin && (
        <div>
          <div id="bulletin-area" className={styles['bulletin-area']}>
            {['FI', 'EN', 'SV', 'ET'].map((lang, index) => (
              <div key={lang}>
                <h4>{lang}</h4>
                <ReactQuill
                  modules={{
                    toolbar: [
                      [{ header: [2, 3, false] }],
                      ['bold', 'italic', 'underline', 'link'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['clean']
                    ]
                  }}
                  onKeyDown={event =>
                    checkCharacterCount(
                      event,
                      [reactQuillRefFi, reactQuillRefEn, reactQuillRefSv, reactQuillRefEt][index]
                    )
                  }
                  ref={el => {
                    [reactQuillRefFi, reactQuillRefEn, reactQuillRefSv, reactQuillRefEt][
                      index
                    ].current = el;
                  }}
                  onChange={value => {
                    switch (index) {
                      case 0:
                        setMessageFi(value);
                        break;
                      case 1:
                        setMessageEn(value);
                        break;
                      case 2:
                        setMessageSv(value);
                        break;
                      case 3:
                        setMessageEt(value);
                        break;
                      default:
                        break;
                    }
                  }}
                  value={[messageFi, messageEn, messageSv, messageEt][index]}
                />
                {t('adminui.currentChars', {
                  currentChars:
                    ([reactQuillRefFi, reactQuillRefEn, reactQuillRefSv, reactQuillRefEt][
                      index
                    ].current
                      ?.getEditor()
                      .getLength() || 1) - 1,
                  maxChars: MAX_LENGTH
                })}
              </div>
            ))}
            <div>
              <ActionButton
                id="preview-button"
                label={t('adminui.previewButton')}
                onClickAction={previewBulletin}
              />
            </div>
            <h4>{t('adminui.attachmentsTitle')}</h4>
            {attachments.map(attachment => (
              <div key={attachment.attachmentId || attachment.id}>
                <span className={styles['attachment-div']}>{attachment.name}</span>
                <span className={styles['attachment-div']}>
                  /api/v1/config/bulletin/{currentBulletin.id}/attachment/
                  {attachment.attachmentId || attachment.id}
                </span>
                <button
                  className={styles.inline}
                  type="button"
                  onClick={() =>
                    removeAttachment(attachment.attachmentId || attachment.id, !!attachment.id)
                  }
                >
                  <div>{t('adminui.removeAttachment')}</div>
                </button>
              </div>
            ))}
            {fileSizeError && <div className={styles.error}>{t('adminui.fileTooLargeError')}</div>}
            {fileFormatError && <div className={styles.error}>{t('adminui.fileFormatError')}</div>}
            <Dropzone onDrop={uploadAttachment}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <div className={styles['button-container']}>
                    <button className={styles.inline} type="button">
                      <div className={styles.element}>{t('adminui.uploadAttachment')}</div>
                    </button>
                  </div>
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
            <Input
              id="time-begin"
              data-cy="time-begin-input"
              label={t('adminui.activationDate')}
              className={styles['time-input']}
              defaultValue={activationDate}
              type="date"
              onValueChange={e => setActivationDate(e.target.value)}
            />
            <Toggle
              key="priority-switch"
              name="priority-switch"
              value={priority}
              data-cy="priority-switch"
              onToggle={() => {
                setPriority(!priority);
              }}
              label={t('adminui.priorityBulletin')}
            />
          </div>
          <ActionButton
            id="save-bulletin-button"
            label={t('adminui.saveBulletin')}
            onClickAction={saveBulletin}
            disabled={!selectedEnvironment || !activationDate || !areTextEditorsFilled()}
            className={styles['save-bulletin-button']}
          />
          <DeleteButton
            id="delete-bulletin-button"
            label={t('adminui.deleteBulletin')}
            // $FlowFixMe
            onClickAction={deleteBulletin}
            disabled={!selectedEnvironment || !currentBulletin?.id}
            className={styles['delete-bulletin-button']}
          />
        </div>
      )}
    </div>
  );
};

export default BulletinEditor;
