// @flow

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from '@design-system/component-library/src/components/Toggle';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import Input from '@design-system/component-library/src/components/Input';
import axios from 'axios';
import ActionButton from '../../../components/Button/ActionButton';
import configOperations from '../../../ducks/config/configOperations';
import Tooltip from '../../../components/Tooltip';
import { actions as notificationActions } from '../../../ducks/ui/notification';
import Dialog from '../../../components/Dialog';
import {
  createCsrfHeader,
  isAdmin,
  isElisaAdmin,
  isServiceProvider
} from '../../../utils/accessRightUtils';
import {
  isValidPeriodicStatsResetMoments,
  MAX_EXTENSION_GROUP_QUEUE_SIZE,
  RESET_MOMENTS_MAX_LENGTH,
  UNREACHABLE_LOGOUT_MAX_LENGTH
} from '../../../fieldValidators';
import styles from './EnterpriseSettings.module.scss';

type PropsT = {|
  +enterpriseId: string
|};

export const AgentStateByPresence = {
  Invalid: 'invalid',
  NotInUse: 'notInUse',
  TogglesPause: 'togglesPause',
  TogglesLogin: 'togglesLogin',
  FromPresenceLoginAndPause(presenceLogin: boolean, presencePause: boolean): string {
    if (presenceLogin && !presencePause) {
      return AgentStateByPresence.TogglesLogin;
    }
    if (!presenceLogin && presencePause) {
      return AgentStateByPresence.TogglesPause;
    }
    if (!presenceLogin && !presencePause) {
      return AgentStateByPresence.NotInUse;
    }
    return AgentStateByPresence.Invalid;
  }
};

export const EnterpriseSettings = (props: PropsT) => {
  const { enterpriseId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const [twoStepAuthEnabled, setTwoStepAuthEnabled] = useState<?boolean>(null);
  const [groupHistoryCallbackEnabled, setGroupHistoryCallbackEnabled] = useState<?boolean>(null);
  const [logoutOnUnreachable, setLogoutOnUnreachable] = useState<?boolean>(null);
  const [unreachableDurationBeforeLogout, setUnreachableDurationBeforeLogout] = useState(null);
  const [logoutOnNoAnswer, setLogoutOnNoAnswer] = useState<?boolean>(null);
  const [nbNoAnswersBeforeLogout, setNbNoAnswersBeforeLogout] = useState(null);
  const [agentStateByPresence, setAgentStateByPresence] = useState('0');
  const [periodicStatsResetMoments, setPeriodicStatsResetMoments] = useState('');
  const [maxExtensionGroupQueueSize, setMaxExtensionGroupQueueSize] = useState();
  const [enterpriseConfigurationId, setEnterpriseConfigurationId] = useState(null);
  const [automaticRecordingDisablePause, setAutomaticRecordingDisablePause] = useState<?boolean>(
    null
  );
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [enterpriseReportingEnabled, setEnterpriseReportingEnabled] = useState<boolean>(false);

  const enterpriseSettings = useSelector(state => state.config.enterpriseSettings);
  const currentUser = useSelector(state => state.currentUser);

  const getEnterpriseConfigurations = async () => {
    const url = `/api/v1/enterprises/${enterpriseId}/services/ivrs`;
    const params = { ivrName: 'EnterpriseConfiguration' };
    const response = axios({
      method: 'GET',
      url,
      params
    });
    const { data } = await response;

    const enterpriseConfiguration = data?.results?.[0]?.ivrProperties?.enterpriseConfiguration;

    if (enterpriseConfiguration) {
      setEnterpriseConfigurationId(data.results[0].id);
      setLogoutOnUnreachable(enterpriseConfiguration.logoutOnUnreachable.value);
      setMaxExtensionGroupQueueSize(enterpriseConfiguration.maxExtensionGroupQueueSize.value);
      setUnreachableDurationBeforeLogout(
        enterpriseConfiguration.unreachableDurationBeforeLogout.value
      );
      setLogoutOnNoAnswer(enterpriseConfiguration.logoutOnNoAnswer.value);
      setNbNoAnswersBeforeLogout(enterpriseConfiguration.nbNoAnswersBeforeLogout.value);
      setPeriodicStatsResetMoments(enterpriseConfiguration.periodicStatsResetMoments.value);
      if (enterpriseConfiguration.presenceLogin && enterpriseConfiguration.presencePause) {
        setAgentStateByPresence(
          AgentStateByPresence.FromPresenceLoginAndPause(
            enterpriseConfiguration.presenceLogin.value,
            enterpriseConfiguration.presencePause.value
          )
        );
      }
    }
  };

  const updateEnterpriseConfigurations = async () => {
    if (enterpriseConfigurationId === null) {
      return false;
    }
    const url = `/api/v1/enterprises/${enterpriseId}/services/ivrs/${enterpriseConfigurationId}/enterpriseconfig`;
    const response = axios({
      method: 'PATCH',
      url,
      headers: createCsrfHeader(currentUser),
      data: {
        ivrProperties: {
          enterpriseConfiguration: {
            logoutOnUnreachable: {
              value: logoutOnUnreachable
            },
            unreachableDurationBeforeLogout: {
              value: unreachableDurationBeforeLogout
            },
            logoutOnNoAnswer: {
              value: logoutOnNoAnswer
            },
            nbNoAnswersBeforeLogout: {
              value: nbNoAnswersBeforeLogout
            },
            presenceLogin: {
              value: agentStateByPresence === AgentStateByPresence.TogglesLogin
            },
            presencePause: {
              value: agentStateByPresence === AgentStateByPresence.TogglesPause
            },
            periodicStatsResetMoments: {
              value: periodicStatsResetMoments
            },
            maxExtensionGroupQueueSize: {
              value: maxExtensionGroupQueueSize
            }
          }
        }
      }
    });
    const returnValue = await response;
    return returnValue;
  };

  useEffect(() => {
    dispatch(configOperations.getEnterpriseSettings(enterpriseId));
    getEnterpriseConfigurations();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (enterpriseSettings && enterpriseSettings[enterpriseId]) {
      setTwoStepAuthEnabled(enterpriseSettings[enterpriseId].twoStepAuthEnabled);
      setGroupHistoryCallbackEnabled(enterpriseSettings[enterpriseId].groupHistoryCallbackEnabled);
      setAutomaticRecordingDisablePause(
        enterpriseSettings[enterpriseId].automaticRecordingDisablePause
      );
      setEnterpriseReportingEnabled(enterpriseSettings[enterpriseId].enterpriseReportingEnabled);
    }
  }, [enterpriseSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const showDialog = () => {
    return (
      (enterpriseSettings &&
        enterpriseSettings[enterpriseId] &&
        enterpriseSettings[enterpriseId].twoStepAuthEnabled &&
        enterpriseSettings[enterpriseId].twoStepAuthEnabled !== twoStepAuthEnabled) ||
      (enterpriseSettings &&
        enterpriseSettings[enterpriseId] &&
        enterpriseSettings[enterpriseId].groupHistoryCallbackEnabled &&
        enterpriseSettings[enterpriseId].groupHistoryCallbackEnabled !==
          groupHistoryCallbackEnabled) ||
      (enterpriseSettings &&
        enterpriseSettings[enterpriseId] &&
        enterpriseSettings[enterpriseId].enterpriseReportingEnabled &&
        enterpriseSettings[enterpriseId].enterpriseReportingEnabled !==
          enterpriseReportingEnabled) ||
      (enterpriseSettings &&
        enterpriseSettings[enterpriseId] &&
        enterpriseSettings[enterpriseId].automaticRecordingDisablePause &&
        enterpriseSettings[enterpriseId].automaticRecordingDisablePause !==
          automaticRecordingDisablePause)
    );
  };

  const saveEnterpriseSettings = async () => {
    let data;
    let saveData = {
      enterpriseId,
      groupHistoryCallbackEnabled
    };
    let returnValue = true;
    if (((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API')) {
      returnValue = await updateEnterpriseConfigurations();
    }
    if (isElisaAdmin(currentUser) || isServiceProvider(currentUser)) {
      saveData = {
        ...saveData,
        twoStepAuthEnabled,
        enterpriseReportingEnabled,
        automaticRecordingDisablePause
      };
      data = dispatch(
        configOperations.updateEnterpriseSettings(saveData, createCsrfHeader(currentUser))
      );
    } else {
      data = dispatch(
        configOperations.updateEnterpriseSettingsAsEnterpriseAdmin(
          saveData,
          createCsrfHeader(currentUser)
        )
      );
    }

    if (data && returnValue) {
      dispatch(
        notificationActions.createCreateNotificationAction({
          tag: 'enterprise-settings-saved',
          duration: 15000,
          type: 'info',
          message: t('enterpriseSettings.saveSuccess')
        })
      );
    } else {
      dispatch(
        notificationActions.createCreateNotificationAction({
          tag: 'enterprise-settings-saved-failure',
          duration: 15000,
          type: 'error',
          message: t('enterpriseSettings.saveFailure')
        })
      );
    }
  };
  return (
    <div className={styles['enterprise-settings-area']}>
      {showConfirmDialog && (
        <Dialog
          title={t('enterpriseSettings.confirmDialogTitle')}
          description={t('enterpriseSettings.confirmDialogDescription')}
          confirmLabel={t('enterpriseSettings.confirmDialogConfirm')}
          cancelLabel={t('enterpriseSettings.confirmDialogCancel')}
          onCancel={() => setShowConfirmDialog(false)}
          onConfirm={() => {
            setShowConfirmDialog(false);
            saveEnterpriseSettings();
          }}
          onClose={() => setShowConfirmDialog(false)}
        />
      )}
      <div className={styles['section-title']}>{t('enterpriseSettings.acdTitle')}</div>
      {(isElisaAdmin(currentUser) || isServiceProvider(currentUser)) && (
        <Input
          id="maxextensiongroupqueuesize-input"
          label={t('enterpriseSettings.maxExtensionGroupQueueSize')}
          onValueChange={e => {
            setMaxExtensionGroupQueueSize(e.target.value);
          }}
          optional
          defaultValue={maxExtensionGroupQueueSize}
          i18n_input_optionalText=""
          maxlength={MAX_EXTENSION_GROUP_QUEUE_SIZE}
          className={styles['number-input']}
        />
      )}
      {isAdmin(currentUser) && (
        <div>
          {((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') && (
            <div>
              <Toggle
                id="logoutonunreachable-toggle"
                onToggle={() => setLogoutOnUnreachable(!logoutOnUnreachable)}
                label={t('enterpriseSettings.logoutOnUnreachable')}
                value={logoutOnUnreachable}
              />
              <Input
                id="logoutonunreachable-input"
                label={t('enterpriseSettings.unreachableDurationBeforeLogout')}
                onValueChange={e => {
                  setUnreachableDurationBeforeLogout(e.target.value);
                }}
                optional
                defaultValue={unreachableDurationBeforeLogout}
                i18n_input_optionalText=""
                maxlength={UNREACHABLE_LOGOUT_MAX_LENGTH}
                disabled={!logoutOnUnreachable}
                className={styles['number-input']}
              />
              <Toggle
                id="logoutonoanswer-toggle"
                onToggle={() => setLogoutOnNoAnswer(!logoutOnNoAnswer)}
                label={t('enterpriseSettings.logoutOnNoAnswer')}
                value={logoutOnNoAnswer}
              />
              <Input
                id="logoutonoanswer-input"
                label={t('enterpriseSettings.nbNoAnswersBeforeLogout')}
                onValueChange={e => {
                  setNbNoAnswersBeforeLogout(e.target.value);
                }}
                optional
                defaultValue={nbNoAnswersBeforeLogout}
                i18n_input_optionalText=""
                maxlength={UNREACHABLE_LOGOUT_MAX_LENGTH}
                disabled={!logoutOnNoAnswer}
                className={styles['number-input']}
              />
              <Dropdown
                label={t('enterpriseSettings.agentStateByPresence.title')}
                id="agent-state-by-presence-dropdown"
                items={[
                  AgentStateByPresence.NotInUse,
                  AgentStateByPresence.TogglesLogin,
                  AgentStateByPresence.TogglesPause
                ].map(v => {
                  return {
                    label: t(`enterpriseSettings.agentStateByPresence.${v}`),
                    value: v
                  };
                })}
                light
                selectedValue={agentStateByPresence}
                onValueChange={element => {
                  setAgentStateByPresence(element.dataset.value);
                }}
                className={styles.dropdown}
              />
              <Input
                id="periodicStatsResetMoments-input"
                label={t('enterpriseSettings.periodicStatsResetMoments')}
                onValueChange={e => {
                  setPeriodicStatsResetMoments(e.target.value);
                }}
                optional
                defaultValue={periodicStatsResetMoments}
                i18n_input_optionalText=""
                i18n_input_infoText={t('enterpriseSettings.periodicStatsResetMomentsInfo')}
                maxlength={RESET_MOMENTS_MAX_LENGTH}
                className={styles.input}
                i18n_input_errorMessage={
                  !isValidPeriodicStatsResetMoments(periodicStatsResetMoments)
                    ? t('enterpriseSettings.periodicStatsResetMomentsInfo')
                    : ''
                }
              />
            </div>
          )}
          <div className={styles['setting-area']}>
            <Toggle
              id="grouphistorycallback-toggle"
              onToggle={() => setGroupHistoryCallbackEnabled(!groupHistoryCallbackEnabled)}
              label={t('enterpriseSettings.groupHistoryCallback')}
              value={groupHistoryCallbackEnabled}
            />
            <Tooltip>
              {parse(
                `<ul style="margin-left:30px;"><li style="list-style-type:disc">${t(
                  'enterpriseSettings.groupHistoryCallback1'
                )}</li><li style="list-style-type:disc">${t(
                  'enterpriseSettings.groupHistoryCallback2'
                )}</li><li style="list-style-type:disc">${t(
                  'enterpriseSettings.groupHistoryCallback3'
                )}</li><li style="list-style-type:disc">${t(
                  'enterpriseSettings.groupHistoryCallback4'
                )}</li></ul>`
              )}
            </Tooltip>
          </div>
        </div>
      )}
      {(isElisaAdmin(currentUser) || isServiceProvider(currentUser)) && (
        <div>
          <div className={styles['section-title--sub']}>{t('enterpriseSettings.title')}</div>
          <div className={styles['setting-area']}>
            <Toggle
              id="twostepauth-toggle"
              onToggle={() => setTwoStepAuthEnabled(!twoStepAuthEnabled)}
              label={t('enterpriseSettings.twoStepAuth')}
              value={twoStepAuthEnabled}
            />
            <Tooltip>
              {parse(
                `<ul style="margin-left:30px;"><li style="list-style-type:disc">${t(
                  'enterpriseSettings.twoStepAuthInfo1'
                )}</li><li style="list-style-type:disc">${t(
                  'enterpriseSettings.twoStepAuthInfo2'
                )}</li><li style="list-style-type:disc">${t(
                  'enterpriseSettings.twoStepAuthInfo3'
                )}</li></ul>`
              )}
            </Tooltip>
          </div>
          <div className={styles['setting-area']}>
            <Toggle
              id="automaticrecordingdisablepause-toggle"
              onToggle={() => setAutomaticRecordingDisablePause(!automaticRecordingDisablePause)}
              label={t('enterpriseSettings.automaticRecordingDisablePause')}
              value={automaticRecordingDisablePause}
            />
            <Tooltip>{t('enterpriseSettings.automaticRecordingDisablePauseTooltip')}</Tooltip>
          </div>
          <div className={styles['section-title--sub']}>
            {t('enterpriseSettings.reportingTitle')}
          </div>
          <Toggle
            id="enterprise-reporting-toggle"
            onToggle={() => setEnterpriseReportingEnabled(!enterpriseReportingEnabled)}
            label={t('enterpriseSettings.reportingLabel')}
            value={enterpriseReportingEnabled}
          />
        </div>
      )}
      <div>
        <ActionButton
          id="save-button"
          className={styles.button}
          label={t('enterpriseSettings.save')}
          disabled={
            ((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') &&
            !isValidPeriodicStatsResetMoments(periodicStatsResetMoments)
          }
          onClickAction={() =>
            showDialog() ? setShowConfirmDialog(true) : saveEnterpriseSettings()
          }
        />
      </div>
    </div>
  );
};

export default EnterpriseSettings;
