// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import { CancelToken, CancelTokenSource } from 'axios';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { compose } from 'redux';
import HTTP from 'http-status-codes';
import type { ExactPropsT } from '../../commonTypes';
import {
  retrieveAudioFile,
  retrieveIvrAudioFile,
  retrieveVoiceMailAudioFile
} from '../../ducks/entities/callFlow/callFlowOperations';
import AudioPlayer from './AudioPlayer';
import type {
  IvrAudioT,
  PropertyAudioT,
  VoiceMailAudioFieldT
} from '../../scenes/callFlows/components/edit/children/audio/CallflowAudioUtils';
import type { AudioLevelT } from '../../ducks/entities/callFlow/callFlowTypes';

type StatePropsT = {||};

type OwnPropsT = {|
  loading?: boolean,
  error?: boolean,
  audio: PropertyAudioT | IvrAudioT | VoiceMailAudioFieldT,
  active?: boolean,
  level?: AudioLevelT,
  customNoAudioLabel?: string,
  audioFilename?: string
|};

type StateT = {
  duration: number,
  currentTime: number,
  isPlaying: boolean,
  dragLeft: number,
  dragX: number,
  isDragging: boolean,
  audioUrl: string,
  loading: boolean,
  error: boolean
};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT, WithTranslationProps>;

export class AudioPlayerContainer extends Component<PropsT, StateT> {
  intervalId: IntervalID;

  retrieveAudioFileRequestCancelTokenSource: CancelTokenSource;

  player: {
    current: null | {
      audio: HTMLAudioElement | null,
      bar: HTMLDivElement | null,
      slider: HTMLDivElement | null
    }
  };

  constructor(props: PropsT) {
    super(props);
    this.state = {
      duration: 0,
      currentTime: 0,
      dragLeft: 0,
      dragX: 0,
      isDragging: false,
      isPlaying: false,
      audioUrl: '',
      loading: typeof props.loading !== 'undefined' ? props.loading : false,
      error: typeof props.error !== 'undefined' ? props.error : false
    };
    this.getAndProcessAudio = this.getAndProcessAudio.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
    this.handlePause = this.handlePause.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.mounted = React.createRef();
    this.retrieveAudioFileRequestCancelTokenSource = CancelToken.source();
    this.player = React.createRef();
  }

  mounted: { current: null | boolean };

  // eslint-disable-next-line class-methods-use-this
  createAudioUrl(audioFile: {}) {
    let blob;

    if (audioFile) {
      blob = new Blob([audioFile], {
        type: 'audio/mpeg'
      });
      return window.URL.createObjectURL(blob);
    }

    return undefined;
  }

  async componentDidMount() {
    const TIME_UPDATE_INTERVAL = 200;
    this.mounted.current = true;
    try {
      await this.getAndProcessAudio();
    } catch (e) {
      // fail silently
    }

    this.intervalId = setInterval(() => {
      if (
        !this.state.isDragging &&
        this.player.current &&
        this.player.current.audio &&
        !!this.player.current.audio.duration
      ) {
        this.updateDisplayTime();
      }
    }, TIME_UPDATE_INTERVAL);
  }

  getAndProcessAudio: () => Promise<void>;

  async getAndProcessAudio() {
    const { audio, audioFilename } = this.props;
    let audioData;
    this.setState({
      loading: true,
      error: false
    });
    try {
      if (audio.audioType === 'property' && audio) {
        const audioName = audio.fieldName.charAt(0).toUpperCase() + audio.fieldName.slice(1);
        let convertedAudio = { service: audio.serviceSettingName, field: audioName, audioFilename };
        if (audio.level) {
          convertedAudio = { ...convertedAudio, level: audio.level };
        }
        audioData = await retrieveAudioFile(
          audio.enterpriseId,
          audio.callflowType || 'acds',
          audio.callflowId,
          convertedAudio,
          this.retrieveAudioFileRequestCancelTokenSource.token
        );
      } else if (audio.audioType === 'name' && audio && audio.filename) {
        audioData = await retrieveIvrAudioFile(
          audio.enterpriseId,
          audio.callflowType || 'acds',
          audio.callflowId,
          audio.filename,
          this.retrieveAudioFileRequestCancelTokenSource.token
        );
      } else if (audio.audioType === 'voicemail' && audio && audio.propertyName) {
        audioData = await retrieveVoiceMailAudioFile(
          audio.enterpriseId,
          audio.callflowId,
          audio.propertyName,
          audio.serviceType,
          this.retrieveAudioFileRequestCancelTokenSource.token
        );
      }
    } catch (error) {
      this.setState({
        loading: false,
        error: error.response.status !== HTTP.NOT_FOUND
      });
      return;
    }
    if (this.mounted.current && !audioData) {
      this.setState({
        loading: false,
        error: true
      });
      return;
    }

    if (this.mounted.current && audioData) {
      if (audioData.byteLength > 0) {
        this.setState({
          loading: false,
          error: false,
          audioUrl: this.createAudioUrl(audioData)
        });
      } else {
        this.setState({
          loading: false,
          error: false
        });
      }
    }
  }

  handlePlay: () => void;

  handlePlay() {
    this.setState({ isPlaying: true });
  }

  handlePause: () => void;

  handlePause() {
    this.setState({ isPlaying: false });
  }

  handleDragStart: DragEvent => void;

  handleDragStart(e: DragEvent) {
    if (!this.player.current || !this.player.current.audio || !this.player.current.audio.src) {
      return;
    }
    if (e.dataTransfer) {
      e.dataTransfer.setData('text', 'slider');
    }
    this.player.current.audio.pause();
    document.addEventListener('dragover', (event: DragEvent) => {
      this.setState({ dragX: event.pageX });
    });
    this.setState({ isDragging: true });
  }

  handleTouchStart: () => void;

  handleTouchStart() {
    this.setState({ isDragging: true });
    if (this.player.current && this.player.current.audio) {
      // $FlowFixMe
      setTimeout(() => this.player.current.audio.pause(), 0);
    }
  }

  handleDrag: Event => void;

  handleDrag(e: Event) {
    if (!this.player.current || !this.player.current.bar || !this.player.current.audio) return;
    // $FlowFixMe
    let dragLeft = e.touches
      ? e.touches[0].clientX - this.player.current.bar.getBoundingClientRect().left
      : this.state.dragX - this.player.current.bar.getBoundingClientRect().left;
    if (dragLeft < 0) {
      dragLeft = 0;
    } else if (dragLeft > this.player.current.bar.offsetWidth - 3) {
      dragLeft = this.player.current.bar.offsetWidth - 4;
    }
    this.player.current.audio.currentTime =
      (this.player.current.audio.duration * dragLeft) / (this.player.current.bar.offsetWidth - 3) ||
      0;
    this.updateDisplayTime(dragLeft);
  }

  handleDragEnd: () => void;

  handleDragEnd() {
    this.setState({ isDragging: false });
    setTimeout(() => {
      if (this.player.current && this.player.current.audio && this.player.current.bar) {
        this.player.current.audio.currentTime =
          (this.player.current.audio.duration * this.state.dragLeft) /
          (this.player.current.bar.offsetWidth - 3);
        this.player.current.audio.play();
      }
    }, 0);
  }

  componentWillUnmount() {
    this.mounted.current = false;
    this.retrieveAudioFileRequestCancelTokenSource.cancel();
    clearInterval(this.intervalId);
    if (this.player.current && this.player.current.audio) {
      this.player.current.audio.removeEventListener('play', this.handlePlay);
      // $FlowFixMe: already null checked
      this.player.current.audio.removeEventListener('pause', this.handlePause);
    }
    if (this.player.current && this.player.current.slider) {
      this.player.current.slider.removeEventListener('drag', this.handleDrag);
      // $FlowFixMe: already null checked
      this.player.current.slider.removeEventListener('touchmove', this.handleDrag);
      // $FlowFixMe: already null checked
      this.player.current.slider.removeEventListener('dragend', this.handleDragEnd);
      // $FlowFixMe: already null checked
      this.player.current.slider.removeEventListener('touchend', this.handleDragEnd);
      // $FlowFixMe: already null checked
      this.player.current.slider.removeEventListener('dragstart', this.handleDragStart);
      // $FlowFixMe: already null checked
      this.player.current.slider.removeEventListener('touchstart', this.handleTouchStart);
    }
  }

  updateDisplayTime = (dragLeft: ?number) => {
    if (!this.player.current || !this.player.current.audio || !this.player.current.bar) return;
    const { currentTime } = this.player.current.audio;
    const { duration } = this.player.current.audio;
    const barWidth = this.player.current.bar.offsetWidth - 3;
    const left = dragLeft || (barWidth * currentTime) / duration || 0;
    this.setState({
      currentTime,
      duration,
      dragLeft: left
    });
  };

  togglePlay = () => {
    if (this.player.current && this.player.current.audio) {
      if (this.player.current.audio.paused && this.player.current.audio.src) {
        this.player.current.audio.play();
      } else if (!this.player.current.audio.paused) {
        this.player.current.audio.pause();
      }
    }
  };

  /* Handle mouse click on progress bar event */
  mouseDownProgressBar = (e: MouseEvent) => {
    const mousePageX = e.pageX;
    if (mousePageX && this.player.current && this.player.current.bar && this.player.current.audio) {
      let dragLeft = mousePageX - this.player.current.bar.getBoundingClientRect().left;
      if (dragLeft < 0) {
        dragLeft = 0;
      } else if (
        this.player.current &&
        this.player.current.bar &&
        dragLeft > this.player.current.bar.offsetWidth - 3
      ) {
        dragLeft = this.player.current.bar.offsetWidth - 4;
      }
      if (this.player.current && this.player.current.audio && this.player.current.bar) {
        this.player.current.audio.currentTime =
          (this.player.current.audio.duration * dragLeft) /
            (this.player.current.bar.offsetWidth - 3) || 0;
      }
      this.updateDisplayTime(dragLeft);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  calculateTime(currentTime: number, duration: number) {
    let currentTimeMin = Math.floor(currentTime / 60);
    let currentTimeSec = Math.floor(currentTime % 60);
    let durationMin = Math.floor(duration / 60);
    let durationSec = Math.floor(duration % 60);
    const addHeadingZero = num => (num > 9 ? num.toString() : `0${num}`);

    currentTimeMin = addHeadingZero(currentTimeMin);
    currentTimeSec = addHeadingZero(currentTimeSec);
    durationMin = addHeadingZero(durationMin);
    durationSec = addHeadingZero(durationSec);

    return {
      currentTimeMin,
      currentTimeSec,
      durationMin,
      durationSec
    };
  }

  componentDidUpdate(oldProps: PropsT, oldState: StateT) {
    if (
      oldState.audioUrl !== this.state.audioUrl &&
      this.player.current &&
      this.player.current.slider
    ) {
      this.addEventListeners();
    }
  }

  addEventListeners() {
    // $FlowFixMe: already null checked
    this.player.current.slider.addEventListener('dragstart', this.handleDragStart);
    // $FlowFixMe: already null checked
    this.player.current.slider.addEventListener('touchstart', this.handleTouchStart);
    // $FlowFixMe: already null checked
    this.player.current.slider.addEventListener('drag', this.handleDrag);
    // $FlowFixMe: already null checked
    this.player.current.slider.addEventListener('touchmove', this.handleDrag);
    // $FlowFixMe: already null checked
    this.player.current.slider.addEventListener('dragend', this.handleDragEnd);
    // $FlowFixMe: already null checked
    this.player.current.slider.addEventListener('touchend', this.handleDragEnd);

    if (this.player.current && this.player.current.audio) {
      // When audio play starts
      this.player.current.audio.addEventListener('play', this.handlePlay);
    }

    if (this.player.current && this.player.current.audio) {
      // When the user pauses playback
      this.player.current.audio.addEventListener('pause', this.handlePause);
    }
  }

  render() {
    const { t, customNoAudioLabel, audio, audioFilename } = this.props;
    const {
      currentTime,
      audioUrl,
      dragLeft,
      dragX,
      duration,
      error,
      loading,
      isDragging,
      isPlaying
    } = this.state;

    const noAudioLabel = customNoAudioLabel || t('callflows.audioPlayerContainer.noAudioLabel');

    return loading || error || audioUrl ? (
      <AudioPlayer
        ref={this.player}
        // $FlowFixMe PropertyAudioT, IvrAudioT or VoiceMailAudioFieldT type
        level={audio.level}
        // $FlowFixMe PropertyAudioT, IvrAudioT or VoiceMailAudioFieldT type
        audioFilename={audioFilename || audio.filename || ''}
        currentTime={currentTime}
        audioUrl={audioUrl}
        dragLeft={dragLeft}
        dragX={dragX}
        duration={duration}
        error={error}
        loading={loading}
        incompatibilityMessage={t('callflows.audioPlayerContainer.noSupport')}
        isDragging={isDragging}
        isPlaying={isPlaying}
        onCalculateTime={this.calculateTime}
        onMouseDownProgressBar={this.mouseDownProgressBar}
        onTogglePlay={this.togglePlay}
        loadingText=""
        errorText={parse(
          `${t('callflows.audioPlayerContainer.fetchErrorMessage1')}<br/>${t(
            'callflows.audioPlayerContainer.fetchErrorMessage2'
          )}${audio.filename || audio.fieldName || ''}`
        )}
        errorButtonText={t('callflows.audioPlayerContainer.fetchErrorRetryLabel')}
        onRetry={this.getAndProcessAudio}
        active={this.props.active === undefined ? true : this.props.active}
      />
    ) : (
      <div>{noAudioLabel}</div>
    );
  }
}

const mapStateToProps = () => ({});

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, null)
)(AudioPlayerContainer);
