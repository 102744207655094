// @flow

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import { ReactComponent as CalendarIcon } from '../../../../../assets/callflow/details/calendar-small.svg';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import WeekDayCalendar from '../../../components/view/children/calendar/WeekDayCalendar';
import useCalendar from '../../../calendar/useCalendar';
import CallflowProperty from '../../../components/view/children/CallflowProperty';

export type PropsT = {|
  callflowId: string
|};

export const AcdCalendarDetails = (props: PropsT): Element<typeof CallflowDetails> | null => {
  const { callflowId } = props;

  const { t } = useTranslation();
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  const selectedCalendarName = acdCallCenter.openHours;

  // State
  const { calendar, isLoadingCalendar } = useCalendar(
    acdData ? acdData.enterpriseId : '',
    selectedCalendarName || ''
  );
  if (!acdData) {
    return null;
  }

  return (
    <CallflowDetails
      enterpriseId={acdData.enterpriseId}
      callflowId={acdData.id}
      elementType={acdData.type}
      nodeId={`acd_calendar_${acdData.id}`}
      nodeType="ACD_CALENDAR"
      icon={<CalendarIcon />}
      title={t('callflows.viewAcdCalendar.title')}
      active
    >
      <CallflowDetailsColumn id="acd-calendar-column-1">
        <CallflowProperty
          id="keepQueuedOnCalendarClosed"
          title={t('callflows.editAcdCalendar.keepQueuedOnCalendarClosed')}
          property={acdCallCenter.keepQueuedOnCalendarClosed}
          tooltip={t('callflows.editAcdCalendar.keepQueuedOnCalendarClosedInfo')}
          basicValue
        />
        {isLoadingCalendar ? (
          <LoadingSpinner />
        ) : (
          <WeekDayCalendar
            title={t('callflows.viewAcdCalendar.openCalendarTitle')}
            selectedCalendar={calendar}
            enterpriseId={acdData.enterpriseId}
          />
        )}
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default AcdCalendarDetails;
