/* eslint-disable flowtype/no-weak-types */
/* eslint-disable no-unused-vars */
// @flow

import React, { type Element, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios, { AxiosPromise } from 'axios';
import { useFormContext } from 'react-hook-form';
import type { Canceler } from 'axios';
import * as yup from 'yup';
import * as R from 'ramda';
import Moment from 'moment';
import uuid from 'uuid/v4';
import { useTranslation } from 'react-i18next';
import { Button } from '@design-system/component-library';
import Toggle from '@design-system/component-library/src/components/Toggle';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import IconEditRegular from '@design-system/component-library/src/components/Icon/lib/IconEditRegular';
import IconDeleteRegular from '@design-system/component-library/src/components/Icon/lib/IconDeleteRegular';
import BaseContainer from '../../BaseContainer/BaseContainer';
import { goToCalendarTemplates, goToEditCalendarTemplate } from '../../../navigationOperations';
import GoBackLink from '../../../components/Button/GoBackLink';
import AbsoluteTimesField from '../calendar/AbsoluteTimesField';
import EditCallflowDetails from '../components/edit/EditCallflowDetails';
import { actions as notificationActions } from '../../../ducks/ui/notification';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../ducks/currentUser/currentUserTypes';
import TopNavigation from '../../navigation/TopNavigation';
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker';
import { findTitleForDate, filterVisibleDates } from './calendarTemplateUtil';
import InputField from '../components/edit/children/InputField';
import CenterHorizontally from '../../../components/CenterHorizontally/CenterHorizontally';
import GenericError from '../../../components/Error/GenericError';
import Dialog from '../../../components/Dialog';
import styles from './EditCalendarTemplate.module.scss';
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatePicker';
import OpeningHoursDialog from '../components/OpeningHoursDialog/OpeningHoursDialog';
import AddHolidaysDialog from './AddHolidaysDialog';
import UsageDialog from './UsageDialog';
import DateTable from './DateTable';

export type PropsT = {|
  match: {
    params: {
      id: string,
      calendarId: string
    }
  }
|};

const EditCalendarTemplate = (props: PropsT): Element<typeof BaseContainer> => {
  const { id: enterpriseId, calendarId } = props.match.params;
  const { t } = useTranslation();

  // redux
  const dispatch = useDispatch();
  const updateCalendarCanceller = React.useRef<Canceler>();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [datePickerSelectedDate, setDatePickerSelectedDate] = useState(null);
  const [calendarData, setCalendarData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [showAddHolidaysDialog, setShowAddHolidaysDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showDeleteDateDialog, setShowDeleteDateDialog] = useState(null);
  const [bankHolidays, setBankHolidays] = useState([]);

  const isNew = () => {
    return calendarId === 'create';
  };

  const calendarTemplateSchema = yup.object().shape({
    name: yup.string().required(t('calendars.editCalendarForm.nameEmptyValidationError'))
  });

  const fetchData = async () => {
    try {
      const response: AxiosPromise = await axios({
        method: 'GET',
        url: `/api/v1/config/bank_holidays`
      });
      if (!response || !response.data) {
        throw new Error('Error getting holiday data');
      }
      setBankHolidays(response.data);

      if (isNew()) {
        // $FlowFixMe
        setCalendarData({
          name: '',
          dates: []
        });
      } else {
        const cResponse: AxiosPromise = await axios({
          method: 'GET',
          url: `/api/v1/enterprises/${enterpriseId}/calendartemplates/${calendarId}`
        });
        if (!cResponse || !cResponse.data) {
          throw new Error('Error getting calendar data');
        }
        const cdata = cResponse.data;
        cdata.dates = cdata.effectiveDates.map(s => {
          return {
            ...s,
            title: findTitleForDate(s.date, response.data, t)
          };
        });
        cdata.dates = filterVisibleDates(cdata.dates);
        setCalendarData(cdata);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.error('Error', error.message);
      }
      setError(true);
    }
    setIsLoading(false);
  };

  const isInUse = () => {
    return (
      calendarData &&
      calendarData.usedInCalendars != null &&
      calendarData.usedInCalendars.length > 0
    );
  };

  const saveDate = async (date, refresh? = true) => {
    const payload = date;
    let response: AxiosPromise;
    setIsLoading(true);

    try {
      const method = date.identifier ? 'PATCH' : 'POST';
      response = await axios({
        method,
        url: `/api/v1/enterprises/${enterpriseId}/calendartemplates/${calendarId}/dates`,
        data: payload,
        headers: createCsrfHeader(currentUser)
      });
    } catch (error) {
      console.error(error);
    }

    const success = !!response;
    if (refresh) fetchData();
  };

  const deleteDate = async date => {
    let response: AxiosPromise;
    setIsLoading(true);
    try {
      response = await axios({
        method: 'DELETE',
        url: `/api/v1/enterprises/${enterpriseId}/calendartemplates/${calendarId}/dates/${date.identifier}`,
        headers: createCsrfHeader(currentUser)
      });
    } catch (error) {
      console.error(error);
    }
    const success = !!response;
    fetchData();
    setShowDeleteDateDialog(null);
  };

  const editDate = dateEntry => {
    setDatePickerSelectedDate(dateEntry);
    setIsDatePickerVisible(true);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  const calendarTopic = (
    <>
      <GoBackLink
        id="callflow-page-back-link"
        text={t('calendars.createCalendar.backToCalendarsLink')}
        onClick={() => dispatch(goToCalendarTemplates(enterpriseId))}
      />
      <div className={styles['subheader-container']}>
        <div className={styles.big}>
          {isNew() ? t('calendars.templates.createTitle') : t('calendars.templates.editTitle')}
        </div>
      </div>
    </>
  );

  const onSaveForm = async (formData: any): Promise<void> => {
    const payload = {
      enterpriseId,
      name: formData.name
    };

    let response: AxiosPromise;

    try {
      if (isNew()) {
        response = await axios({
          method: 'POST',
          url: `/api/v1/enterprises/${enterpriseId}/calendartemplates`,
          data: payload,
          headers: createCsrfHeader(currentUser)
        });
      } else {
        // $FlowFixMe
        payload.id = calendarId;

        response = await axios({
          method: 'PATCH',
          url: `/api/v1/enterprises/${enterpriseId}/calendartemplates/${calendarId}`,
          data: payload,
          headers: createCsrfHeader(currentUser)
        });
      }
    } catch (error) {
      console.error(error);
    }

    const success = !!response;
    dispatch(
      notificationActions.createCreateNotificationAction({
        tag: success ? `calendar-edit-success` : `calendar-edit-fail`,
        duration: 15000,
        type: success ? 'info' : 'error',
        message: success
          ? t('calendars.templates.saveSuccessNotification')
          : t('calendars.templates.saveFailureNotification')
      })
    );

    const newId = response?.data?.id;

    if (!isNew() || !newId) {
      dispatch(goToCalendarTemplates(enterpriseId));
    } else {
      dispatch(goToEditCalendarTemplate(enterpriseId, `${newId}`));
    }
  };

  const deleteCalendar = async () => {
    if (isNew()) return;

    const response: AxiosPromise = await axios({
      method: 'DELETE',
      url: `/api/v1/enterprises/${enterpriseId}/calendartemplates/${calendarId}`,
      headers: createCsrfHeader(currentUser)
    });

    const success = !!response;
    dispatch(
      notificationActions.createCreateNotificationAction({
        tag: success ? `calendar-delete-success` : `calendar-delete-fail`,
        duration: 15000,
        type: success ? 'info' : 'error',
        message: success
          ? t('calendars.templates.deleteSuccessNotification')
          : t('calendars.templates.deleteFailureNotification')
      })
    );
    dispatch(goToCalendarTemplates(enterpriseId));
  };

  return (
    <BaseContainer header={<TopNavigation />} containerStyle={styles.container}>
      {calendarTopic}
      {isError && <GenericError message={t('efax.efaxListFailedMsg')} />}
      {isLoading && (
        <CenterHorizontally>
          <LoadingSpinner />
        </CenterHorizontally>
      )}
      {calendarData && (
        <>
          <div className={styles.container}>
            <EditCallflowDetails
              nodeId="calendars-page"
              doNotCloseOnSubmit
              icon={null}
              title=""
              onSaveForm={formData => onSaveForm(formData)}
              onCancel={e => dispatch(goToCalendarTemplates(enterpriseId))}
              defaultValues={calendarData}
              deleteButtonLabel={isNew() ? '' : t('generic.commonAction.delete')}
              onDelete={() => {
                setIsLoading(true);
                fetchData();
                setShowDeleteDialog(true);
              }}
              validationSchema={calendarTemplateSchema}
            >
              <div className={styles['form-container']}>
                <div className={styles['form-section']}>
                  <InputField
                    field="name"
                    title={t('calendars.editCalendarForm.name')}
                    description={t('calendars.editCalendarForm.nameDescription')}
                    maxLength={107}
                    shouldValidate
                  />
                </div>
                {!isNew() && (
                  <>
                    <DateTable
                      editable
                      templateName=""
                      dates={calendarData.dates}
                      onEdit={d => {
                        editDate(d);
                      }}
                      onDelete={d => {
                        setShowDeleteDateDialog(d);
                      }}
                    />

                    <div className={styles['form-section']}>
                      <div className={styles['flex-container']}>
                        <div>
                          <div className={styles['section-title']}>
                            {t('calendars.templates.includeBankHolidaysTitle')}
                          </div>
                          <div className={styles['info-text']}>
                            {t('calendars.templates.bankHolidaysInfoText')}
                          </div>
                          <Button
                            className={styles.button}
                            size="m"
                            color="light"
                            onClick={() => {
                              setShowAddHolidaysDialog(true);
                            }}
                          >
                            {t('calendars.templates.includeBankHolidaysButton')}
                          </Button>
                        </div>
                        <div>
                          <div className={styles['section-title']}>
                            {t('calendars.templates.singleDateTitle')}
                          </div>
                          <div className={styles['info-text']}>
                            {t('calendars.templates.singleDateInfoText')}
                          </div>
                          <Button
                            className={styles.button}
                            size="m"
                            color="light"
                            onClick={() => {
                              editDate(null);
                            }}
                          >
                            {t('calendars.templates.singleDateButton')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div>
                {isDatePickerVisible && (
                  <OpeningHoursDialog
                    title={t('calendars.templates.addDateDialogTitle')}
                    holidays={bankHolidays}
                    dates={calendarData?.dates}
                    initialDate={datePickerSelectedDate}
                    onCancel={() => {
                      setIsDatePickerVisible(false);
                      setDatePickerSelectedDate(null);
                    }}
                    onConfirm={val => {
                      console.log(val);
                      saveDate(val);
                      setIsDatePickerVisible(false);
                      setDatePickerSelectedDate(null);
                    }}
                    onDelete={val => {
                      setShowDeleteDateDialog(val);
                    }}
                  />
                )}

                {showAddHolidaysDialog ? (
                  <AddHolidaysDialog
                    onCancel={() => {
                      setShowAddHolidaysDialog(false);
                    }}
                    onConfirm={(selectedHolidays, openingHoursDefintion) => {
                      const startDate = Moment().format('YYYY-MM-DD');
                      const endDate = Moment()
                        .add(1, 'y')
                        .format('YYYY-MM-DD');

                      const nextYearHolidays = bankHolidays.filter(h => {
                        return h.date >= startDate && h.date <= endDate;
                      });

                      if (selectedHolidays.includes('all_official')) {
                        selectedHolidays.splice(selectedHolidays.indexOf('all_official'), 1);
                        nextYearHolidays.forEach(h => {
                          if (h.official) {
                            selectedHolidays.push(h.name);
                          }
                        });
                      }

                      const newDates = [];
                      selectedHolidays.forEach(sh => {
                        const dt = nextYearHolidays.find(h => h.name === sh);
                        if (!dt) {
                          console.error(`no holiday found for name ${sh}`);
                        } else {
                          const newDateEntry = {
                            date: Moment(dt.date ? dt.date : null).format('YYYY-MM-DD'),
                            identifier: null,
                            slots: openingHoursDefintion.closedAllDay
                              ? null
                              : openingHoursDefintion.slots,
                            closedAllDay: openingHoursDefintion.closedAllDay,
                            repeatsYearly: false,
                            repeatsYearlyAtHoliday: openingHoursDefintion.repeats ? sh : null
                          };
                          newDates.push(newDateEntry);
                        }
                      });

                      newDates.forEach(d => {
                        saveDate(d, false);
                      });
                      fetchData();
                      setShowAddHolidaysDialog(false);
                    }}
                  />
                ) : null}

                {showDeleteDateDialog ? (
                  <Dialog
                    onCancel={() => setShowDeleteDateDialog(null)}
                    onConfirm={() => {
                      deleteDate(showDeleteDateDialog);
                    }}
                    onClose={() => {
                      setShowDeleteDateDialog(null);
                    }}
                    title={t('calendars.templates.deleteDateDialogTitle')}
                    description={`${Moment(showDeleteDateDialog.date).format('D.M.YYYY')} ${
                      showDeleteDateDialog.title
                    }
                    ${
                      showDeleteDateDialog.closedAllDay
                        ? t('callflows.details.closed')
                        : showDeleteDateDialog.slots.map(s => {
                            return `${s.startTime.substring(0, 5)}-${s.endTime.substring(0, 5)}`;
                          })
                    }
                    ${
                      showDeleteDateDialog.repeatsYearly ||
                      showDeleteDateDialog.repeatsYearlyAtHoliday
                        ? `, ${t('calendars.templates.addHolidaysDialogRepeatLabel')}`
                        : ''
                    }`}
                    confirmLabel={t('calendars.templates.dialogActions.confirm')}
                    cancelLabel={t('calendars.templates.dialogActions.cancel')}
                    loading={false}
                    disabled={false}
                  />
                ) : null}

                {showDeleteDialog && !isLoading && !isInUse() && (
                  <Dialog
                    onCancel={() => setShowDeleteDialog(false)}
                    onConfirm={() => {
                      deleteCalendar();
                    }}
                    onClose={() => {
                      setShowDeleteDialog(false);
                    }}
                    title={t('calendars.templates.deleteDialogTitle')}
                    description=""
                    confirmLabel={t('calendars.templates.dialogActions.confirm')}
                    cancelLabel={t('calendars.templates.dialogActions.cancel')}
                    loading={false}
                    disabled={false}
                  />
                )}
                {showDeleteDialog && !isLoading && isInUse() && (
                  <UsageDialog
                    usedInCalendars={calendarData.usedInCalendars}
                    enterpriseId={enterpriseId}
                    onClose={() => {
                      setShowDeleteDialog(false);
                    }}
                  />
                )}
              </div>
            </EditCallflowDetails>
          </div>
        </>
      )}
    </BaseContainer>
  );
};

export default EditCalendarTemplate;
