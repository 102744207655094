// @flow
/* eslint-disable import/prefer-default-export */

import * as R from 'ramda';
import type { CurrentUserT } from '../../ducks/currentUser/currentUserTypes';
import { getConfigurationValue } from '../../userConfigHelpers';
import type { UserConfigT } from '../../ducks/config';
import type { PushObjT } from '../../navigationOperations';
import type { BulletinT } from '../../ducks/config/configTypes';
import { ONBOARDING_DISPLAYED_CONFIG } from '../OnboardingModal/OnboardingModal';

export const getGotoDestination = (
  currentUser: CurrentUserT,
  username?: string,
  password?: string
): PushObjT | null => {
  if (currentUser.status === 'generic_login_error') {
    return {
      pathname: '/login',
      state: {}
    };
  }
  if (currentUser.status === 'elisa_ad_platform_missing_error') {
    return {
      pathname: '/login-with-elisa-ad',
      state: { username, password }
    };
  }
  if (currentUser.status === 'access_right_error') {
    return {
      pathname: '/no-admin-access-rights-error',
      state: {}
    };
  }
  if (currentUser.status === 'missing_role_access_right_error') {
    return {
      pathname: '/missing-role-access-right-error',
      state: {}
    };
  }
  if (currentUser.status === 'two_step_authentication') {
    return {
      pathname: '/two-step-authentication',
      state: { username, password, token: currentUser.token }
    };
  }
  return null;
};

export const getChangelogKey = (environment: string) => {
  return `changelog_displayed_${environment}`;
};

export const isResellersVisible = (ownerAdmtiveDomainIds: string[], bulletin: BulletinT) => {
  const { resellersVisibility, resellerIds } = bulletin;

  if (resellersVisibility === 'default') return true;

  if (resellersVisibility === 'only') {
    return (
      resellerIds?.some(id => ownerAdmtiveDomainIds.some(domainId => domainId.startsWith(id))) ??
      true
    );
  }

  if (resellersVisibility === 'exclude') {
    return (
      resellerIds?.every(id => !ownerAdmtiveDomainIds.some(domainId => domainId.startsWith(id))) ??
      true
    );
  }

  return true;
};

export const findEnvironmentBulletins = (
  environment: string,
  ownerAdmtiveDomainIds: string[],
  bulletins: BulletinT[]
): BulletinT[] => {
  return bulletins
    ? bulletins
        .filter(
          bulletin =>
            bulletin.activationDate &&
            bulletin.environment &&
            bulletin.environment
              .split(',')
              .map(env => env.trim())
              .includes(environment) &&
            isResellersVisible(ownerAdmtiveDomainIds, bulletin)
        )
        .sort((a, b) => Date.parse(b.activationDate) - Date.parse(a.activationDate))
    : [];
};

export const getBulletin = (
  environment: string,
  ownerAdmtiveDomainIds: string[],
  bulletins: BulletinT[]
) => {
  const environmentBulletins = findEnvironmentBulletins(
    environment,
    ownerAdmtiveDomainIds,
    bulletins
  );
  return environmentBulletins.find(b => new Date() >= Date.parse(b.activationDate));
};

export const isBulletinVisibleTo = (bulletin: BulletinT, currentUser: CurrentUserT): boolean => {
  if (!bulletin.activationDate) return false;
  if (new Date() < Date.parse(bulletin.activationDate)) return false;
  if (!bulletin.environment) return false;
  const environments = bulletin.environment.split(',').map(env => env.trim());
  if (!environments.includes(`${currentUser.environment}${currentUser.country || ''}`))
    return false;
  return isResellersVisible(
    currentUser.enterprises
      ? currentUser.enterprises.map(enterprise => enterprise.ownerAdmtiveDomainId || '')
      : [],
    bulletin
  );
};

export const getSortedAndFilteredBulletins = (
  currentUser: CurrentUserT,
  bulletins: BulletinT[]
): BulletinT[] => {
  return bulletins
    .filter(b => isBulletinVisibleTo(b, currentUser))
    .sort((a, b) => Date.parse(b.activationDate) - Date.parse(a.activationDate));
};

export const getNewChangelogDisplayedValue = (
  environment: string,
  ownerAdmtiveDomainId: string[],
  bulletins: BulletinT[],
  currentChangelogDisplayedValue: ?string
) => {
  const foundBulletin = getBulletin(environment, ownerAdmtiveDomainId, bulletins);
  const currentValues = currentChangelogDisplayedValue
    ? currentChangelogDisplayedValue.split(',')
    : '';
  if (foundBulletin && foundBulletin.id) {
    const found = currentValues
      ? // $FlowFixMe
        currentValues.find(value => value === foundBulletin.id.toString()) !== undefined
      : false;
    return !found
      ? // $FlowFixMe
        `${currentChangelogDisplayedValue || ''},${foundBulletin.id}`
      : currentChangelogDisplayedValue;
  }
  return currentChangelogDisplayedValue;
};

export const getLoginConfigs = (
  environment: string,
  ownerAdmtiveDomainIds: string[],
  userConfig: UserConfigT,
  bulletins: BulletinT[]
) => {
  const changelogDisplayedKey = getChangelogKey(environment);
  if (!userConfig) {
    // eslint-disable-next-line no-param-reassign
    userConfig = [];
  }
  if (!bulletins) {
    // eslint-disable-next-line no-param-reassign
    bulletins = [];
  }
  const currentChangelogDisplayedValue = getConfigurationValue(userConfig, changelogDisplayedKey);
  const newChangelogDisplayedValue = getNewChangelogDisplayedValue(
    environment,
    ownerAdmtiveDomainIds,
    bulletins,
    currentChangelogDisplayedValue
  );
  const shouldOpenChangelog = currentChangelogDisplayedValue !== newChangelogDisplayedValue;
  const shouldOpenOnboarding =
    getConfigurationValue(userConfig, ONBOARDING_DISPLAYED_CONFIG) !== 'true';
  let modifiedUserConfig = null;
  if (shouldOpenOnboarding) {
    const changelogValue: string = bulletins
      .filter(
        bulletin =>
          bulletin.environment &&
          bulletin.environment.includes(environment) &&
          isResellersVisible(ownerAdmtiveDomainIds, bulletin) &&
          new Date() >= Date.parse(bulletin.activationDate)
      )
      .map(b => (b && b.id ? `${b.id}` : ''))
      .join();
    modifiedUserConfig = [
      ...R.reject(item => 'key' in item && item.key === changelogDisplayedKey, userConfig),
      { key: changelogDisplayedKey, value: changelogValue }
    ];
  } else if (shouldOpenChangelog && changelogDisplayedKey && newChangelogDisplayedValue) {
    modifiedUserConfig = [
      ...R.reject(item => 'key' in item && item.key === changelogDisplayedKey, userConfig),
      { key: changelogDisplayedKey, value: newChangelogDisplayedValue }
    ];
  }

  return { modifiedUserConfig, shouldOpenOnboarding, shouldOpenChangelog };
};

export const isUserLanguageValid = (appLanguage: string) =>
  appLanguage && ['en', 'sv', 'fi', 'et'].includes(appLanguage);
