// @flow

import React, { type Element, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import type Moment from 'moment';
import Modal from '@design-system/component-library/src/components/Modal';
import Button from '@design-system/component-library/src/components/Button';
import Toggle from '@design-system/component-library/src/components/Toggle';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import Label from '@design-system/component-library/src/components/Label';
import Input from '@design-system/component-library/src/components/Input';
import 'moment/locale/fi';
import 'moment/locale/sv';
import 'moment/locale/et';
import { CancelToken } from 'axios';
import type { Canceler } from 'axios';
import HTTP from 'http-status-codes';
import { useTranslation } from 'react-i18next';
import TimePicker from 'react-time-picker';
import {
  closePopovers,
  getAvailabilityInfo,
  isAbsoluteTimeSlot,
  parsePresenceDate
} from '../../helpers';
import AvailabilityIndicator from '../Avatar/AvailabilityIndicator';
import {
  createCalendarSlot,
  updateCalendarSlot
} from '../../ducks/entities/calendar/calendarOperations';
import PresenceCallForwarding from '../PresenceCallForwarding/PresenceCallForwarding';
import type { InternalUserStateEntityT } from '../../ducks/entities/user/userTypes';
import { createCallForwarding } from '../../ducks/entities/callForwarding/callForwardingOperations';
import { goToForwardingDetailsUpdate } from '../../navigationOperations';
import fieldValidators from '../../fieldValidators';
import { actions as notificationActions } from '../../ducks/ui/notification';
import { createCsrfHeader } from '../../utils/accessRightUtils';
import type { CurrentUserT } from '../../ducks/currentUser/currentUserTypes';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import styles from './CreateUpdatePresenceModal.module.scss';

export type PropsT = {
  enterpriseId: string,
  calendarId: string,
  userId: string,
  modalRef: *,
  selectedCalendarSlot?: *,
  reloadFunc: () => void,
  changeCurrentState?: boolean
};

const CreateUpdatePresenceModal = ({
  enterpriseId,
  calendarId,
  userId,
  modalRef,
  selectedCalendarSlot,
  reloadFunc,
  changeCurrentState
}: PropsT): Element<'div'> => {
  const { t, i18n } = useTranslation();
  const statuses = [
    'WORKING',
    'CUSTOMER_MEETING',
    'TRAINING',
    'BUSINESS_TRIP',
    'LUNCH',
    'AWAY',
    'HOLIDAYS'
  ];
  const activeEndTimes = ['HALF_HOUR', 'HOUR', 'END_OF_THE_DAY', 'SELECT_TIME'];
  const [selectedStatus, setSelectedStatus] = useState(statuses[0]);
  const [extraInfo, setExtraInfo] = useState();
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState<string>('00:00');
  const [endTime, setEndTime] = useState<string>('00:00');
  const [endDate, setEndDate] = useState();
  const [editUserForwarding, setEditUserForwarding] = useState();
  const [selectedEndTime, setSelectedEndTime] = useState('SELECT_TIME');
  const [forwardingTarget, setForwardingTarget] = useState({ type: 'INVALID', value: '' });
  const activeLanguage = i18n.language;
  const [allDay, setAllDay] = useState();
  const user: InternalUserStateEntityT = useSelector(state => state.entities.user.byId[userId]);
  const [isRepetition, setIsRepetition] = useState();
  const [repetitionDays, setRepetitionDays] = useState([]);
  const [errorMsg, setErrorMsg] = useState();
  const [isUpdating, setIsUpdating] = useState();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const dispatch = useDispatch();
  const updateCalendarCanceller = React.useRef<Canceler>();

  const userForwarding =
    user && user.forwardings
      ? user.forwardings.find(
          fwd =>
            fwd.isActive &&
            fwd.presenceStateFilter &&
            fwd.presenceStateFilter.includes(selectedStatus)
        )
      : null;

  const fixRepetitionDaysToStartFromSunday = () => {
    return repetitionDays.map(day => {
      let fixedWeekDay = day;
      if (activeLanguage !== 'en') {
        fixedWeekDay += 2;
        if (fixedWeekDay > 7) {
          fixedWeekDay = 1;
        }
      } else {
        fixedWeekDay += 1;
      }
      return fixedWeekDay;
    });
  };

  const saveCallForwarding = async () => {
    let callflowData;
    let callflowId = userForwarding ? userForwarding.id : '';
    if (!userForwarding && forwardingTarget.type !== 'INVALID') {
      const callflowPayload = {
        label: `${selectedStatus} -> ${forwardingTarget.type}`,
        forwardingType: 'ALWAYS',
        destination: forwardingTarget,
        presenceStateFilter: [selectedStatus]
      };
      callflowData = await dispatch(
        createCallForwarding(
          enterpriseId,
          callflowPayload,
          user.id,
          null,
          createCsrfHeader(currentUser)
        )
      );
      callflowId = callflowData.id;
    }
    if (
      editUserForwarding &&
      ((forwardingTarget.type !== 'INVALID' && callflowData && !callflowData.error) ||
        userForwarding)
    ) {
      dispatch(goToForwardingDetailsUpdate(enterpriseId, callflowId, user.addressNumber));
    }
    return callflowData;
  };

  const generateStartEndTime = () => {
    let startDateTime;
    if (startDate) {
      startDateTime = moment(startDate);
    }
    let endDateTime;
    if (endDate) {
      endDateTime = moment(endDate);
    }
    if (selectedEndTime !== 'SELECT_TIME') {
      startDateTime = moment();
      if (selectedEndTime === 'HALF_HOUR') {
        endDateTime = moment().add(30, 'minutes');
      } else if (selectedEndTime === 'HOUR') {
        endDateTime = moment().add(1, 'hours');
      } else if (selectedEndTime === 'END_OF_THE_DAY') {
        endDateTime = moment()
          .add(1, 'days')
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          });
      }
    } else if (allDay) {
      if (!startDateTime) {
        startDateTime = moment();
      }
      startDateTime.set({
        hour: 0,
        minute: 0,
        second: 0
      });
      if (!endDateTime) {
        endDateTime = moment();
      }
      endDateTime.set({
        hour: 24,
        minute: 0,
        second: 0
      });
    } else {
      if (isRepetition) {
        startDateTime = moment();
        endDateTime = moment();
      }
      if (startDateTime && startTime) {
        startDateTime.set({
          hour: parseInt(startTime.split(':')[0], 10),
          minute: parseInt(startTime.split(':')[1], 10)
        });
      }
      if (endDateTime && endTime) {
        endDateTime.set({
          hour: parseInt(endTime.split(':')[0], 10),
          minute: parseInt(endTime.split(':')[1], 10)
        });
      }
    }
    return { startDateTime, endDateTime };
  };

  const onCreateCalendarSlot = async () => {
    // eslint-disable-next-line prefer-const
    let { startDateTime, endDateTime } = generateStartEndTime();

    if (changeCurrentState) {
      startDateTime = moment();
    }
    if (startDateTime) {
      let patchData = {
        presenceState: selectedStatus,
        customPresenceState: extraInfo
      };
      if (!isRepetition) {
        patchData = {
          ...patchData,
          startDateTime: startDateTime.valueOf(),
          endDateTime: endDateTime ? endDateTime.valueOf() : null,
          isAbsoluteTimeSlot: true
        };
      } else if (endDateTime) {
        const midnight = moment().startOf('day');
        // Difference in minutes
        const diffStartMinutes = startDateTime.diff(midnight, 'minutes');
        const diffEndMinutes = endDateTime.diff(midnight, 'minutes');
        const fixedRepetitionDays = fixRepetitionDaysToStartFromSunday();
        patchData = {
          ...patchData,
          daysOfWeek: fixedRepetitionDays,
          startTime: diffStartMinutes,
          endTime: diffEndMinutes
        };
      }
      const returnedCalendarSlotValue = await dispatch(
        createCalendarSlot(
          enterpriseId,
          calendarId,
          patchData,
          new CancelToken(canceler => {
            updateCalendarCanceller.current = canceler;
          }),
          createCsrfHeader(currentUser)
        )
      );
      if (returnedCalendarSlotValue && returnedCalendarSlotValue.data) {
        const returnedCallForwarding = await saveCallForwarding();

        if (!returnedCallForwarding || !returnedCallForwarding.error) {
          reloadFunc();
          setIsUpdating(false);
          if (modalRef.current) {
            modalRef.current.closeModal();
          }
          dispatch(
            notificationActions.createCreateNotificationAction({
              tag: 'presence-create-success',
              duration: 15000,
              type: 'info',
              message: t('presenceCalendarListing.createSuccess')
            })
          );
        }
      } else {
        setIsUpdating(false);
        dispatch(
          notificationActions.createCreateNotificationAction({
            tag: 'presence-create-failure',
            duration: 15000,
            type: 'error',
            message:
              returnedCalendarSlotValue &&
              returnedCalendarSlotValue.response &&
              returnedCalendarSlotValue.response.status === HTTP.UNPROCESSABLE_ENTITY
                ? t('presenceCalendarListing.createFailureOverlapping')
                : t('presenceCalendarListing.createFailure')
          })
        );
      }
    }
  };

  const modifyCalendarSlot = async () => {
    // eslint-disable-next-line prefer-const
    let { startDateTime, endDateTime } = generateStartEndTime();

    if (selectedCalendarSlot && startDateTime) {
      let patchData = {
        presenceState: selectedStatus,
        customPresenceState: extraInfo
      };
      if (isAbsoluteTimeSlot(selectedCalendarSlot)) {
        patchData = {
          ...patchData,
          startDateTime: startDateTime.valueOf(),
          endDateTime: endDateTime ? endDateTime.valueOf() : null,
          isAbsoluteTimeSlot: true
        };
      } else if (endDateTime) {
        const midnight = moment().startOf('day');
        // Difference in minutes
        const diffStartMinutes = startDateTime.diff(midnight, 'minutes');
        const diffEndMinutes = endDateTime.diff(midnight, 'minutes');
        const fixedRepetitionDays = fixRepetitionDaysToStartFromSunday();
        patchData = {
          ...patchData,
          daysOfWeek: fixedRepetitionDays,
          linkId: selectedCalendarSlot.linkId,
          startTime: diffStartMinutes,
          endTime: diffEndMinutes
        };
      }
      const returnedCalendarSlotValue = await dispatch(
        updateCalendarSlot(
          enterpriseId,
          calendarId,
          selectedCalendarSlot.id,
          patchData,
          new CancelToken(canceler => {
            updateCalendarCanceller.current = canceler;
          }),
          createCsrfHeader(currentUser)
        )
      );
      if (returnedCalendarSlotValue && returnedCalendarSlotValue.data) {
        const returnedCallForwarding = await saveCallForwarding();

        if (!returnedCallForwarding || !returnedCallForwarding.error) {
          reloadFunc();
          setIsUpdating(false);
          if (modalRef.current) {
            modalRef.current.closeModal();
          }
          dispatch(
            notificationActions.createCreateNotificationAction({
              tag: 'presence-update-success',
              duration: 15000,
              type: 'info',
              message: t('presenceCalendarListing.updateSuccess')
            })
          );
        }
      } else {
        setIsUpdating(false);
        dispatch(
          notificationActions.createCreateNotificationAction({
            tag: 'presence-update-failure',
            duration: 15000,
            type: 'error',
            message:
              returnedCalendarSlotValue &&
              returnedCalendarSlotValue.response &&
              returnedCalendarSlotValue.response.status === HTTP.UNPROCESSABLE_ENTITY
                ? t('presenceCalendarListing.updateFailureOverlapping')
                : t('presenceCalendarListing.updateFailure')
          })
        );
      }
    }
  };

  const parseRepetitionDays = weekSchedule =>
    Object.keys(weekSchedule).reduce((indices, day, index) => {
      if (
        selectedCalendarSlot &&
        weekSchedule[day].some(d => d.linkId === selectedCalendarSlot.linkId)
      ) {
        indices.push(index);
      }
      return indices;
    }, []);

  useEffect(() => {
    if (selectedCalendarSlot) {
      if (selectedCalendarSlot.presenceState === 'SICK') {
        setSelectedStatus('AWAY');
      } else {
        setSelectedStatus(selectedCalendarSlot.presenceState);
      }
      setExtraInfo(selectedCalendarSlot.customPresenceState);
      const startDateTime: Moment = parsePresenceDate(selectedCalendarSlot.startTime);
      const endDateTime: ?Moment = selectedCalendarSlot.endTime
        ? parsePresenceDate(selectedCalendarSlot.endTime)
        : undefined;
      setStartTime(startDateTime.format('HH:mm'));
      setStartDate(startDateTime.toDate());
      setIsRepetition(!isAbsoluteTimeSlot(selectedCalendarSlot));
      setRepetitionDays(parseRepetitionDays(selectedCalendarSlot.weekSchedule ?? []));
      if (endDateTime) {
        setEndTime(endDateTime.format('HH:mm'));
        setEndDate(endDateTime.toDate());
      }
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
      setStartTime('00:00');
      setEndTime('00:00');
    }
    return () => {};
  }, [selectedCalendarSlot]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedEndTime('SELECT_TIME');
    setStartDate(undefined);
    setEndDate(undefined);
    setStartTime('00:00');
    setEndTime('00:00');
    return () => {};
  }, []);

  const startTimeField = () => (
    <TimePicker
      id="startTimeField"
      data-cy="startTimeField"
      name="startTimeField"
      disableClock
      className={styles['time-input']}
      onChange={value => {
        setStartTime(value);
      }}
      value={startTime}
      disabled={allDay}
      locale="fi-FI"
      format="HH:mm"
    />
  );

  const endTimeField = () => (
    <TimePicker
      id="endTimeField"
      data-cy="endTimeField"
      name="endTimeField"
      disableClock
      className={styles['time-input']}
      onChange={value => {
        setEndTime(value);
      }}
      value={endTime}
      disabled={allDay}
      locale="fi-FI"
      format="HH:mm"
    />
  );

  const generateWeekDays = () => {
    return (
      <div className={styles['day-list']}>
        {[0, 1, 2, 3, 4, 5, 6].map(day => (
          <Checkbox
            id={`weekday_${day}_check`}
            key={`weekday_${day}_check`}
            name="weekdaysCheck"
            label={moment()
              .locale(activeLanguage || 'fi')
              .weekday(day)
              .format('dd')}
            checked={repetitionDays.includes(day)}
            onChange={() => {
              if (repetitionDays.includes(day)) {
                setRepetitionDays(repetitionDays.filter(item => item !== day) ?? []);
              } else {
                setRepetitionDays(() => [...repetitionDays, day]);
              }
            }}
          />
        ))}
      </div>
    );
  };

  const generateDialogContent = () => {
    const fixPresenceState = () => {
      if (selectedCalendarSlot) {
        if (selectedCalendarSlot.presenceState === 'SICK') {
          return 'AWAY';
        }
        return selectedCalendarSlot.presenceState;
      }
      return '';
    };

    return (
      <div>
        <Dropdown
          id="status-dropdown"
          label={t('presenceCalendarListing.statusTitle')}
          name="status-dropdown"
          items={statuses.map(status => {
            const availability = getAvailabilityInfo(status, null, t);
            return {
              label: (
                <div className={styles.status}>
                  <AvailabilityIndicator
                    className={styles['status-icon']}
                    status={availability.icon}
                    size="xsmall"
                  />
                  {availability.text}
                </div>
              ),
              value: status
            };
          })}
          selectedValue={fixPresenceState()}
          onValueChange={element => {
            setSelectedStatus(element.dataset.value);
          }}
        />
        <Input
          id="extra-info"
          label={t('presenceCalendarListing.extraInfoTitle')}
          name="extra-info"
          type="text"
          maxlength={300}
          clear
          optional
          i18n_input_optionalText=""
          defaultValue={selectedCalendarSlot ? selectedCalendarSlot.customPresenceState : ''}
          onValueChange={event => {
            event.preventDefault();
            setExtraInfo(event.target.value);
          }}
          onClearInput={() => {
            setExtraInfo('');
          }}
        />
        {((selectedCalendarSlot && isRepetition) || !selectedCalendarSlot) && !changeCurrentState && (
          <div data-cy="repetition" className={styles['repetition-area']}>
            <Toggle
              label={t('presenceCalendarListing.repetition')}
              i18n_toggle_ariaLabel={t('presenceCalendarListing.repetition')}
              value={isRepetition}
              disabled={selectedCalendarSlot && isRepetition}
              onToggle={() => {
                setRepetitionDays([]);
                setIsRepetition(!isRepetition);
                setAllDay(false);
                setStartDate();
                setEndDate();
                const el = document.querySelector('#allDayCheckbox');
                // $FlowFixMe
                if (el && el.checked) {
                  el.click();
                }
              }}
            />
            {isRepetition && generateWeekDays()}
          </div>
        )}
        {!changeCurrentState && (
          <div>
            <Dropdown
              label={t('presenceCalendarListing.statusActive')}
              id="status-active"
              name="status-active"
              items={activeEndTimes.map(time => ({
                label: t(`presenceCalendarListing.${time}`),
                value: time
              }))}
              light
              selectedValue={selectedEndTime}
              onValueChange={element => {
                setSelectedEndTime(element.dataset.value);
              }}
            />
            {selectedEndTime === 'SELECT_TIME' && (
              <div>
                {(selectedCalendarSlot && isAbsoluteTimeSlot(selectedCalendarSlot)) ||
                (!selectedCalendarSlot && !isRepetition) ? (
                  <div>
                    <div className={styles['date-input-area']}>
                      <div className={styles['date-input']}>
                        <Label className="ds-input--labelarea-label">
                          {t('presenceCalendarListing.startDateTitle')}
                        </Label>
                        <CustomDatePicker
                          name="datestart"
                          onChange={data => {
                            let startTimeDate;
                            if (!startDate) {
                              startTimeDate = moment();
                            } else {
                              startTimeDate = moment(startDate);
                            }
                            const newStartDate = moment(data, 'DD.MM.YYYY');
                            startTimeDate.set({
                              date: newStartDate.date(),
                              month: newStartDate.month(),
                              year: newStartDate.year()
                            });
                            setStartDate(startTimeDate.toDate());
                          }}
                          minDate={new Date()}
                          selected={startDate}
                        />
                      </div>
                      <div className={styles['time-start-field']}>{startTimeField()}</div>
                    </div>

                    <div className={styles['date-input-area']}>
                      <div className={styles['date-input']}>
                        <Label className="ds-input--labelarea-label">
                          {t('presenceCalendarListing.endDateTitle')}
                        </Label>
                        <CustomDatePicker
                          name="dateend"
                          onChange={data => {
                            let endTimeDate;
                            if (!endDate) {
                              endTimeDate = moment();
                            } else {
                              endTimeDate = moment(endDate);
                            }
                            const newEndDate = moment(data, 'DD.MM.YYYY');
                            endTimeDate.set({
                              date: newEndDate.date(),
                              month: newEndDate.month(),
                              year: newEndDate.year()
                            });
                            setEndDate(endTimeDate.toDate());
                          }}
                          minDate={new Date()}
                          selected={endDate}
                        />
                      </div>
                      <div className={styles['time-start-field']}>{endTimeField()}</div>
                    </div>
                  </div>
                ) : (
                  <div className={styles['time-fields']}>
                    {startTimeField()}
                    <div className={styles['time-fields--middle']}> - </div>
                    {endTimeField()}
                  </div>
                )}
                <Toggle
                  id="allDayCheckbox"
                  value={allDay}
                  onToggle={() => {
                    setAllDay(!allDay);
                  }}
                  className={styles.checkbox}
                  label={t('presenceCalendarListing.allDayTitle')}
                />
              </div>
            )}
          </div>
        )}
        <PresenceCallForwarding
          enterpriseId={enterpriseId}
          userForwarding={userForwarding}
          forwardingTarget={forwardingTarget}
          updateForwardingTarget={setForwardingTarget}
        />
        <Checkbox
          id="editUserForwarding"
          name="editUserForwarding"
          checked={editUserForwarding}
          onChange={() => {
            setEditUserForwarding(!editUserForwarding);
          }}
          disabled={!userForwarding && forwardingTarget.type === 'INVALID'}
          className={styles['cf-checkbox']}
          label={t('presenceCalendarListing.linkToForwarding')}
        />
        {errorMsg && (
          <div data-cy="error-msg" className={styles.error}>
            {errorMsg}
          </div>
        )}
      </div>
    );
  };

  const validateFields = () => {
    const { startDateTime, endDateTime } = generateStartEndTime();
    if (!changeCurrentState) {
      if (!allDay && selectedEndTime === 'SELECT_TIME') {
        if (!startDateTime || !endDateTime) {
          setErrorMsg(t('presenceCalendarListing.timeNotSetError'));
          return false;
        }
        if (endDateTime && !endDateTime.isAfter(startDateTime, 'minutes')) {
          setErrorMsg(t('presenceCalendarListing.endTimeBeforeStartTimeError'));
          return false;
        }
      }
      if (isRepetition && repetitionDays.length === 0) {
        setErrorMsg(t('presenceCalendarListing.repetitionError'));
        return false;
      }
    }
    if (!userForwarding) {
      if (
        (forwardingTarget.type === 'INTERNAL' && !forwardingTarget.value) ||
        (forwardingTarget.type === 'EXTERNAL' &&
          (!forwardingTarget.value ||
            fieldValidators.phoneNumberValidator(forwardingTarget.value) !== undefined))
      ) {
        setErrorMsg(t('presenceCalendarListing.targetError'));
        return false;
      }
    }
    setErrorMsg();
    return true;
  };

  const getHeader = () => {
    if (changeCurrentState) {
      return t('presenceCalendarListing.currentStateTitle');
    }
    return selectedCalendarSlot
      ? t('presenceCalendarListing.modifyTitle')
      : t('presenceCalendarListing.createTitle');
  };

  return (
    <Modal
      ref={modalRef}
      className={styles['presence-modal']}
      autoOpen={false}
      id="modifyModal"
      size="s"
      heading={getHeader()}
      content={generateDialogContent()}
      closeWithBackdropClick={false}
      autoFocus={false}
      onModalOpen={() => {
        setSelectedEndTime('SELECT_TIME');
        setIsUpdating(false);
        setAllDay(false);
        setIsRepetition(false);
        setStartDate(undefined);
        setEndDate(undefined);
        setStartTime('00:00');
        setEndTime('00:00');
        setErrorMsg(undefined);
        setEditUserForwarding(false);
        setExtraInfo('');
        setSelectedStatus(statuses[0]);
        setForwardingTarget({ type: 'INVALID', value: '' });
      }}
      buttons={[
        <Button
          key="btnCancel"
          id="btnCancel"
          color="link"
          onClick={() => {
            closePopovers();
            if (modalRef.current) {
              modalRef.current.closeModal();
            }
          }}
          className={styles.button}
        >
          {t('presenceCalendarListing.cancel')}
        </Button>,
        <Button
          key="btnConfirm"
          id="btnConfirm"
          color="primary"
          loading={isUpdating}
          onClick={async () => {
            if (validateFields()) {
              setIsUpdating(true);
              closePopovers();
              if (selectedCalendarSlot) {
                await modifyCalendarSlot();
              } else {
                await onCreateCalendarSlot();
              }
            }
          }}
          className={styles.button}
        >
          {t('presenceCalendarListing.confirmModify')}
        </Button>
      ]}
    />
  );
};
export default CreateUpdatePresenceModal;
