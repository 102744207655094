// @flow

import * as R from 'ramda';

export type ValidatorFnT = (string, ?string) => ?string;
type MaxLengthValidatorFnT = (string, number, ?string) => ?string;
type MinLengthValidatorFnT = (string, number, ?string) => ?string;

export const EMAILS_MAX_LENGTH = 200;
export const TITLE_MAX_LENGTH = 255;
export const OFFICE_REFERENCE_ID_MAX_LENGTH = 255;
export const NICKNAME_MAX_LENGTH = 100;

export const FORWARDING_LABEL_MAX_LENGTH = 70;

export const PHONE_NUMBER_MAX_LENGTH = 15;
export const SPECIFIC_CALLER_MAX_LENGTH = 500;
export const ADDITIONAL_EXPLANATIONS_MAX_LENGTH = 4000;

export const CONTACT_INFORMATION_MAX_LENGTH = 65535;
export const ADDITIONAL_INFO_MAX_LENGTH = 4000;
export const PHONE_MAX_LENGTH = 20;
export const CORPORATE_USER_ID_MAX_LENGTH = 50;

export const COST_CENTER_MAX_LENGTH = 500;
export const SITE_NAME_MAX_LENGTH = 45;
export const SITE_ADDRESS_MAX_LENGTH = 45;

export const ACCESS_CTRL_SYSTEM_PERSON_ID_MAX_LENGTH = 255;

export const EXT_FIRSTNAME_MAX_LENGTH = 40;
export const EXT_LASTNAME_MAX_LENGTH = 40;
export const EXT_TITLE_MAX_LENGTH = 100;
export const EXT_COMPANY_MAX_LENGTH = 100;
export const EXT_NICKNAME_MAX_LENGTH = 40;
export const EXT_ADDRESS_MAX_LENGTH = 500;
export const EXT_WEBSITE_MAX_LENGTH = 80;
export const EXT_EMAIL_MAX_LENGTH = 120;

export const UNREACHABLE_LOGOUT_MAX_LENGTH = 4;
export const RESET_MOMENTS_MAX_LENGTH = 255;
export const MAX_EXTENSION_GROUP_QUEUE_SIZE = 2;

export const isValidPeriodicStatsResetMoments: string => boolean = value =>
  /^((0[0-9]|1[0-9]|2[0-3]):[0-5][0-9],)*((0[0-9]|1[0-9]|2[0-3]):[0-5][0-9])$/i.test(value);

const emailValidator: ValidatorFnT = (value, msg = '') =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? msg : undefined;

const emailsValidator = (value, msg, separator, separatorCount) => {
  const validTokens = value
    .split(separator)
    .map(email => email.trim())
    .filter(email => email.length > 0);

  if (separatorCount === 0) {
    return emailValidator(value, msg);
  }
  return validTokens.length !== separatorCount + 1 ||
    validTokens.some(email => emailValidator(email) !== undefined) ||
    R.uniq(validTokens).length !== validTokens.length
    ? msg
    : undefined;
};

const commaSeparatedUniqueEmailsValidator: ValidatorFnT = (value, msg = '') => {
  if (value) {
    const separatorCount = (value.match(/,/g) || []).length;
    return emailsValidator(value, msg, ',', separatorCount);
  }
  return undefined;
};

const semicolonSeparatedUniqueEmailsValidator: ValidatorFnT = (value, msg = '') => {
  if (value) {
    const separatorCount = (value.match(/;/g) || []).length;
    return emailsValidator(value, msg, ';', separatorCount);
  }
  return undefined;
};

const phoneNumberRegex = /^\+[0-9]{1,3}?\d{10,14}$/;
const simplePhoneNumberRegex = /(^\+[0-9]{1,3}?\d{8,14}$)|(^\d{10,14}$)/;
const extensionNumberRegex = /^\d{3,7}$/;
const phoneOrExtensionNumberRegex = /(^\d{3,7}$)|(^\+[0-9]{1,3}?\d{10,14}$)/;
const externalContactPhoneNumberRegex = /^([+\-\s\d,])+$/;
const externalContactPhoneNumberStrictRegex = /^\+358([-\s\d])+$/;

const phoneNumberValidator: ValidatorFnT = (value, msg = '') =>
  value && !simplePhoneNumberRegex.test(value) ? msg : undefined;

const E164PhoneNumberValidator: ValidatorFnT = (value, msg = '') =>
  value && !phoneNumberRegex.test(value) ? msg : undefined;

const externalContactPhoneNumberValidator: ValidatorFnT = (value, msg = '') =>
  value && !externalContactPhoneNumberRegex.test(value) ? msg : undefined;

const externalContactPhoneNumberStrictValidator: ValidatorFnT = (value, msg = '') =>
  value && !externalContactPhoneNumberStrictRegex.test(value) ? msg : undefined;

const simpleNumberValidator = (value: ?string, msg: string = ''): ?string =>
  value && !/^\+?[0-9]{1,20}$/.test(value) ? msg : undefined;

const addressNumberValidator: ValidatorFnT = (value, msg = '') =>
  value && !extensionNumberRegex.test(value) ? msg : undefined;

const validateForwardingTargetNumber: ValidatorFnT = (value, msg = ''): ?string =>
  E164PhoneNumberValidator(value, msg) && addressNumberValidator(value, msg);

const maxLengthValidator: MaxLengthValidatorFnT = (value, maxLength, msg = '') =>
  value && value.length > maxLength ? msg : undefined;

const minLengthValidator: MinLengthValidatorFnT = (value, minLength, msg = '') =>
  value && value.length < minLength ? msg : undefined;

const oneTimePasswordValidator: ValidatorFnT = (value, msg = ''): ?string =>
  !/^[0-9]{6}$/i.test(value) ? msg : undefined;

const passwordComplexityValidator: ValidatorFnT = (value, msg = '') =>
  value && !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-_.!~*'()%@]).{8,20}$/.test(value)
    ? msg
    : undefined;

const isPasswordAllSameNumber = (value: string) => /^([0-9])\1*$/.test(value);

const isContinuingSameNumberMaxTimes = (value: ?string, maxTimes: number) => {
  if (!value || value.length < maxTimes) {
    return false;
  }
  let count = 1;
  let previousMatch = value[0];
  for (let i = 1; i < value.length; i++) {
    if (value[i] === previousMatch) {
      count += 1;
      if (count >= maxTimes) {
        return true;
      }
    } else {
      count = 1;
    }
    previousMatch = value[i];
  }
  return false;
};

const containsOnlyNumbers = (value: ?string) => /^[0-9]+$/i.test(value || '');

const isSequentialNumber = (value: ?string) => {
  if (!value) {
    return false;
  }
  const maxMatchSize = 6;
  const deniedSequence = '0123456789876543210';
  for (let i = 0; i <= value.length - maxMatchSize; i++) {
    if (deniedSequence.includes(value.substring(i, i + maxMatchSize))) {
      return true;
    }
  }
  return false;
};

const userPasswordComplexityValidator: ValidatorFnT = (value, errorMsg = '') => {
  if (!value) {
    return errorMsg;
  }

  if (!/^[0-9]{6,20}$/i.test(value)) {
    return errorMsg;
  }

  if (isContinuingSameNumberMaxTimes(value, 6)) {
    return errorMsg;
  }

  if (isSequentialNumber(value)) {
    return errorMsg;
  }

  return undefined;
};

const departmentNameFieldValidator: ValidatorFnT = (value, msg = '') =>
  value && (value.length > 45 || !/^[A-Z\u00E4\u00F6\u00E5\u00FC\u00F5\-_'0-9 ]+$/i.test(value))
    ? msg
    : undefined;

const enterpriseNameFieldValidator: ValidatorFnT = (value, msg = '') =>
  value && (value.length > 128 || !/^[A-Z\u00E4\u00F6\u00E5\u00FC\u00F5\-_'0-9 ]+$/i.test(value))
    ? msg
    : undefined;

const locationNameFieldValidator: ValidatorFnT = (value, msg = '') =>
  value &&
  (value.trim().length === 0 ||
    value.length > SITE_NAME_MAX_LENGTH ||
    !/^[A-Z\u00E4\u00F6\u00E5\u00FC\u00F5\-_'0-9 ]+$/i.test(value))
    ? msg
    : undefined;

const urlValidator: ValidatorFnT = (value, msg = '') =>
  value && !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/.test(value)
    ? msg
    : undefined;

const genericAddressFieldValidator: ValidatorFnT = (value, msg = '') =>
  value &&
  (value.length > SITE_ADDRESS_MAX_LENGTH ||
    !/^[A-Z\u00E4\u00F6\u00E5\u00FC\u00F5\-_'0-9 ]+$/i.test(value))
    ? msg
    : undefined;

const validateSpecificCaller: ValidatorFnT = (value, msg = '') => {
  if (!value) return undefined;
  return value.split(',').every(segment => {
    const digitCount = (segment.match(/\d/g) || []).length;
    const pattern = digitCount === 2 ? /^\^?\+?\d{2}\*$/ : /^\^?\+?\d{2,15}\*?\d{0,15}$/;
    return digitCount <= 15 && pattern.test(segment);
  })
    ? undefined
    : msg;
};

const requiredValidator: ValidatorFnT = (value, msg = '') => (value ? undefined : msg);

export const hasLowerAndUpperCase = (str: ?string): boolean => {
  return !!str && /^(?=.*[a-z])(?=.*[A-Z]).+$/.test(str);
};

export const validateNewAdminPassword = (value: ?string): boolean => {
  if (!value) {
    return false;
  }
  const validLength = value.length <= 20 && value.length >= 8;
  const hasVariousLetterCases = hasLowerAndUpperCase(value);
  const containsNumber = /\d/.test(value);
  const hasSpecialChar = /[-_.!~*’()%@]/.test(value);
  return validLength && hasVariousLetterCases && containsNumber && hasSpecialChar;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  emailValidator,
  phoneNumberValidator,
  enterpriseNameFieldValidator,
  locationNameFieldValidator,
  commaSeparatedUniqueEmailsValidator,
  E164PhoneNumberValidator,
  simpleNumberValidator,
  maxLengthValidator,
  minLengthValidator,
  oneTimePasswordValidator,
  passwordComplexityValidator,
  userPasswordComplexityValidator,
  isPasswordAllSameNumber,
  isSequentialNumber,
  isContinuingSameNumberMaxTimes,
  containsOnlyNumbers,
  departmentNameFieldValidator,
  urlValidator,
  genericAddressFieldValidator,
  requiredValidator,
  validateSpecificCaller,
  validateForwardingTargetNumber,
  addressNumberValidator,
  validateNewAdminPassword,
  hasLowerAndUpperCase,
  phoneNumberRegex,
  extensionNumberRegex,
  phoneOrExtensionNumberRegex,
  externalContactPhoneNumberValidator,
  externalContactPhoneNumberStrictValidator,
  semicolonSeparatedUniqueEmailsValidator
};
