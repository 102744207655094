// @flow

import React, { type Element, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { CancelToken, CancelTokenSource } from 'axios';
import { useTranslation } from 'react-i18next';
import type {
  CalendarEntityT,
  WelcomeAttendantEntityT
} from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantTypes';
import { ReactComponent as CalendarIcon } from '../../../../../../assets/callflow/details/calendar-small.svg';
import CallflowDetails from '../../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../../components/view/CallflowDetailsColumn';
import CallflowItem from '../../../../components/view/children/CallflowItem';
import WeekDayCalendar from '../../../../components/view/children/calendar/WeekDayCalendar';
import type { BaseCalendarEntityT } from '../../../../../../ducks/entities/calendar/calendarTypes';
import { operations as calendarOps } from '../../../../../../ducks/entities/calendar';

export type PropsT = {|
  callflowId: string,
  commandId: string
|};

let requestCancelTokenSource: CancelTokenSource;

export const WelcomeAttendantCalendarDetails = (
  props: PropsT
): Element<typeof CallflowDetails> | null => {
  const { callflowId, commandId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const welcomeAttendant: WelcomeAttendantEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  // $FlowFixMe
  const calendar: ?CalendarEntityT = R.path(['commands', commandId], welcomeAttendant);

  // State
  const [selectedCalendar, setSelectedCalendar] = useState<?BaseCalendarEntityT>({});

  useEffect(() => {
    requestCancelTokenSource = CancelToken.source();
    const fetchCalendar = async () => {
      setSelectedCalendar({});
      const calendarName: ?string =
        calendar && calendar.calendars.length > 0 && calendar.calendars[0]
          ? calendar.calendars[0].name
          : undefined;

      if (welcomeAttendant.enterpriseId && calendarName) {
        const returnValue: {
          results: BaseCalendarEntityT[],
          totalCount: number
        } = await dispatch(
          calendarOps.retrieveCalendars(
            welcomeAttendant.enterpriseId,
            calendarName,
            requestCancelTokenSource.token
          )
        );
        if (returnValue && returnValue.totalCount > 0) {
          setSelectedCalendar(returnValue.results[0]);
        }
      }
    };
    fetchCalendar();
    return () => {
      requestCancelTokenSource.cancel();
    };
  }, [calendar]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!calendar) {
    return null;
  }
  return (
    <CallflowDetails
      enterpriseId={welcomeAttendant.enterpriseId}
      callflowId={welcomeAttendant.id}
      elementType="WELCOME_ATTENDANT"
      nodeId={calendar.name.toUpperCase()}
      nodeType={calendar.type}
      icon={<CalendarIcon />}
      title={t('callflows.viewWelcomeAttendantCalendar.title')}
      active
    >
      <CallflowDetailsColumn id="wa-calendar-column-1">
        <CallflowItem
          id={`step-name-${calendar.name}`}
          title={t('callflows.welcomeAttendantGeneric.stepName')}
          value={calendar ? calendar.name : ''}
        />
        <WeekDayCalendar
          title={t('callflows.viewWelcomeAttendantCalendar.calendar')}
          selectedCalendar={selectedCalendar}
          enterpriseId={welcomeAttendant.enterpriseId}
        />
        <CallflowItem
          id="withinPeriodSate"
          title={t('callflows.viewWelcomeAttendantCalendar.withinPeriodSate')}
          value={
            calendar && calendar.withinPeriodSate.state
              ? calendar.withinPeriodSate.state
              : t('callflows.viewWelcomeAttendantCalendar.targetNotSet')
          }
        />
        <CallflowItem
          id="outOfPeriodState"
          title={t('callflows.viewWelcomeAttendantCalendar.outOfPeriodState')}
          value={
            calendar && calendar.outOfPeriodState.state
              ? calendar.outOfPeriodState.state
              : t('callflows.viewWelcomeAttendantCalendar.targetNotSet')
          }
        />
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default WelcomeAttendantCalendarDetails;
