// @flow

import React, { type Element } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CallFlow from '../scenes/callFlows/singlePage/CallFlow';
import CallFlows from '../scenes/callFlows/CallFlows';
import ForwardingDetails from '../scenes/callForwardings/ForwardingDetails/ForwardingDetails';
import Enterprise from '../scenes/enterprises/Enterprise';
import Enterprises from '../scenes/enterprises/Enterprises';
import Users from '../scenes/users/Users';
import CallForwardings from '../scenes/callForwardings/CallForwardings';
import History from '../scenes/historyActions/HistoryActions';
import CreateUser from '../scenes/users/UserDetails/CreateUser';
import UserDetails from '../scenes/users/UserDetails/UserDetails';
import CreateCalendar from '../scenes/callFlows/calendar/CreateCalendar';
import EditCalendarTemplate from '../scenes/callFlows/calendarTemplates/EditCalendarTemplate';
import CreateCallflow from '../scenes/callFlows/create/CreateCallflow';
import useEnterprise from '../scenes/useEnterprise';
import {
  isAcdManager,
  isSingleEnterpriseAdmin,
  isSwitchboardUser
} from '../utils/accessRightUtils';

type MatchParamsT = {
  id: string,
  url: string
};

const EnterpriseRoutes = ({ match }: { match: { params: MatchParamsT } }): Element<'div'> => {
  const { id: enterpriseId } = match.params;
  const currentUser = useSelector(state => state.currentUser);

  useEnterprise(enterpriseId);
  let redirect = <Redirect to="/enterprises" />;
  if (isSingleEnterpriseAdmin(currentUser) || isSwitchboardUser(currentUser)) {
    redirect = <Redirect to="/enterprises/:id/users" />;
  } else if (isAcdManager(currentUser)) {
    redirect = <Redirect to="/enterprises/:id/callflows" />;
  }

  return (
    <div>
      <Switch>
        <Route exact path="/enterprises/:id/departments/:departmentId" component={Enterprise} />
        <Route exact path="/enterprises/:id/users/external/create" component={CreateUser} />
        <Route exact path="/enterprises/:id/users/:userId/edit" component={UserDetails} />
        <Route exact path="/enterprises/:id/users/:userId" component={Users} />
        <Route exact path="/enterprises/:id/users" component={Users} />
        <Route
          exact
          path="/enterprises/:id/forwardings/create/:forwardingType"
          component={ForwardingDetails}
        />
        <Route
          exact
          path="/enterprises/:id/forwardings/:forwardingId"
          component={ForwardingDetails}
        />
        <Route exact path="/enterprises/:id/forwardings" component={CallForwardings} />
        <Route exact path="/enterprises/:id/callflows/services/:callflowId" component={CallFlow} />
        <Route exact path="/enterprises/:id/callflows/create" component={CreateCallflow} />
        <Route
          exact
          path="/enterprises/:id/callflows/calendars/create"
          component={CreateCalendar}
        />
        <Route
          exact
          path="/enterprises/:id/callflows/calendartemplates/:calendarId"
          component={EditCalendarTemplate}
        />
        <Route exact path="/enterprises/:id/callflows/:tab/:objectId" component={CallFlows} />
        <Route exact path="/enterprises/:id/callflows/:tab" component={CallFlows} />
        <Route exact path="/enterprises/:id/callflows" component={CallFlows} />
        <Route exact path="/enterprises/:id/historyactions" component={History} />
        <Route exact path="/enterprises/:id" component={Enterprise} />
        <Route exact path="/enterprises" component={Enterprises} />
        {redirect}
      </Switch>
    </div>
  );
};

export default EnterpriseRoutes;
