/* eslint-disable flowtype/no-weak-types */
// @flow

import React, { type Element, useState } from 'react';
import Toggle from '@design-system/component-library/src/components/Toggle';
import { Button } from '@design-system/component-library';
import { useTranslation } from 'react-i18next';
import { OFFICE_HOURS } from './calendarTemplateUtil';
import Dismiss from '../../../components/Button/Dismiss';
import TimeSlotEditor from '../components/OpeningHoursDialog/TimeSlotEditor';
import 'react-time-picker/dist/TimePicker.css';
import styles from './AddHolidaysDialog.module.scss';

export type PropsT = {
  onCancel: (*) => *,
  onConfirm: (any, any) => *,
  onClose?: (*) => *
};

const AddHolidaysDialog = ({ onCancel, onConfirm, onClose }: PropsT): Element<'div'> => {
  const { t } = useTranslation();
  const [selectedDate, _setSelectedDate] = useState({
    slots: [],
    closedAllDay: true,
    repeats: false
  });

  const [closedAllDay, setClosedAllDay] = useState(selectedDate.closedAllDay);
  const [timeSlots, setTimeSlots] = useState(selectedDate.slots);

  const [selectedHolidays, setSelectedHolidays] = useState([]);

  const setSelectedDate = date => {
    _setSelectedDate(date);
    setClosedAllDay(date.closedAllDay);
    setTimeSlots(date.slots);
  };

  const toggleHoliday = (name: string) => {
    if (selectedHolidays.includes(name)) {
      selectedHolidays.splice(selectedHolidays.indexOf(name), 1);
    } else {
      selectedHolidays.push(name);
    }
    setSelectedHolidays(selectedHolidays);
  };

  const toggleClosedAllDay = () => {
    const val = !selectedDate.closedAllDay;
    selectedDate.closedAllDay = val;
    if (!val) {
      selectedDate.slots.push(OFFICE_HOURS);
    } else {
      selectedDate.slots = [];
    }
    setClosedAllDay(val);
  };

  const handleTimeSlotsUpdated = slots => {
    selectedDate.slots = slots;
    setSelectedDate({
      ...selectedDate
    });
  };

  const renderToggleFor = (holiday: string, infoText?: string): Element<'div'> => {
    return (
      <Toggle
        key={`holidays-toggle-${holiday}`}
        name={`holidays-toggle-${holiday}`}
        data-cy={`holidays-toggle-${holiday}`}
        i18n_toggle_infoText={infoText}
        labelPosition="right"
        value={selectedHolidays.includes(holiday)}
        className={styles.toggle}
        onToggle={() => {
          toggleHoliday(holiday);
        }}
        label={t(`holidays.${holiday}`)}
      />
    );
  };

  return (
    <div
      className={`ea-modal ea-modal--open styleguide-dialog-position ${styles.container}`}
      role="dialog"
      aria-modal="true"
    >
      <div className="ea-modal__overlay" />
      <div className={`ea-modal__content ${styles.content}`}>
        {onClose && <Dismiss id="close-button" onClose={onClose} />}
        <div className={styles.box}>
          <h2 id="dialog-title" className="ea-h3 ea-h3--thick">
            {t('calendars.templates.addHolidaysDialogTitle')}
          </h2>
          <div className={styles['flex-container']}>
            <div>
              <div className={styles.subheader}>
                {t('calendars.templates.addHolidaysDialogHolidaysTitle')}
              </div>
              <div className={styles.infotext}>
                {t('calendars.templates.addHolidaysDialogHolidaysHelp')}
              </div>
              {renderToggleFor(
                'all_official',
                t('calendars.templates.addHolidaysDialogHolidayList')
              )}
              {renderToggleFor('christmas_eve')}
              {renderToggleFor('midsummers_eve')}
            </div>
            <div>
              <div className={styles.subheader}>{t('calendars.templates.openingHoursTitle')}</div>
              <div className={styles.infotext}>{t('calendars.templates.openingHoursHelp')}</div>
              <Toggle
                key="allday-switch"
                name="allday-switch"
                labelPosition="right"
                value={closedAllDay}
                className={styles.toggle}
                data-cy="allday-switch"
                onToggle={() => {
                  toggleClosedAllDay();
                }}
                label={t('generic.dateTimePicker.allDay')}
              />
              {!closedAllDay && (
                <TimeSlotEditor
                  slots={timeSlots}
                  disabled={false}
                  onUpdated={handleTimeSlotsUpdated}
                />
              )}

              <div className={styles.subheader}>
                {t('calendars.templates.addHolidaysDialogRepeatTitle')}
              </div>
              <div className={styles.infotext}>
                {t('calendars.templates.addHolidaysDialogRepeatHelp')}
              </div>
              <Toggle
                key="repeat-toggle"
                name="repeat-toggle"
                data-cy="repeat-toggle"
                labelPosition="right"
                value={selectedDate.repeats}
                className={styles.toggle}
                onToggle={() => {
                  selectedDate.repeats = !selectedDate.repeats;
                }}
                label={t('calendars.templates.addHolidaysDialogRepeatLabel')}
              />

              <div className={styles.buttons}>
                <Button size="l" color="link" onClick={onCancel}>
                  {t('calendars.templates.dialogActions.cancel')}
                </Button>
                <Button
                  size="l"
                  color="primary"
                  onClick={() => onConfirm(selectedHolidays, selectedDate)}
                >
                  {t('calendars.templates.dialogActions.save')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHolidaysDialog;
