// @flow

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import EditCallflowDetails from '../components/edit/EditCallflowDetails';
import InputField from '../components/edit/children/InputField';
import CalendarField from '../components/edit/children/calendar/CalendarField';
import type {
  AbsoluteTimeSlotEntityT,
  BaseCalendarEntityT,
  WeeklySlotT
} from '../../../ducks/entities/calendar/calendarTypes';
import CalendarConverterUtils from '../components/edit/children/calendar/CalendarConverterUtils';
import { enterpriseCalendarRegExp } from '../../../helpers';

import styles from './EditCalendarForm.module.scss';

export type FormT = {
  label: string,
  weekSlots?: WeeklySlotT[],
  absoluteTimeSlots: AbsoluteTimeSlotEntityT[]
};

export type PropsT = {|
  onCancel: () => void,
  calendar: ?BaseCalendarEntityT,
  showLabel?: boolean,
  onSaveForm: FormT => Promise<void>
|};

const EditCalendarForm = (props: PropsT): Element<'div'> => {
  const { onCancel, calendar, showLabel, onSaveForm } = props;
  const { t } = useTranslation();
  // redux
  const allCalendars = useSelector(state => state.entities.calendar);
  const calendarNames = R.values(allCalendars.byId).map(c => c.name);

  // form
  const initialValues: FormT = {
    label: calendar ? calendar.name : '',
    weekSlots: CalendarConverterUtils.convertToWeekScheduleFormField(calendar),
    absoluteTimeSlots: R.path(['absoluteTimeSlots'], calendar) || []
  };

  const calendarSchema = showLabel
    ? yup.object().shape({
        label: yup
          .string()
          .required(t('calendars.editCalendarForm.nameEmptyValidationError'))
          .matches(/^[a-zA-Z0-9\-_.!%@+']+$/, t('calendars.editCalendarForm.nameValidationError'))
          .test('is-unique', t('calendars.editCalendarForm.nameValidationUniqueError'), value =>
            value
              ? !calendarNames
                  .map(name => name.replace(enterpriseCalendarRegExp, '').toLowerCase())
                  .includes(value.toLowerCase())
              : true
          )
      })
    : null;

  return (
    <div className={styles.container}>
      <EditCallflowDetails
        nodeId="calendars-page"
        icon={null}
        title=""
        defaultValues={initialValues}
        validationSchema={calendarSchema}
        disableCallflowNotifications
        onSaveForm={formData => onSaveForm(formData)}
        onCancel={onCancel}
      >
        {showLabel ? (
          <InputField
            field="label"
            title={t('calendars.editCalendarForm.name')}
            description={t('calendars.editCalendarForm.nameDescription')}
            shouldValidate={showLabel}
            maxLength={107}
          />
        ) : null}
        <CalendarField field="weekSlots" title={t('calendars.editCalendarForm.calendarTitle')} />
      </EditCallflowDetails>
    </div>
  );
};

export default EditCalendarForm;
