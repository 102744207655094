// @flow

/* eslint-disable no-use-before-define */

import type {
  ActionT,
  LanguageConfigActionT,
  UserConfigActionT,
  ConfigStateT,
  BulletinsActionT,
  EnterpriseSettingsActionT
} from './configTypes';

const initialState: ConfigStateT = {
  languages: undefined,
  userConfig: [],
  bulletins: [],
  enterpriseSettings: {},
  __metadata: {}
};

const languageReducer = (
  state: ConfigStateT = initialState,
  action: LanguageConfigActionT
): ConfigStateT => {
  switch (action.type) {
    case 'ring/config/GET_LANGUAGE_REQUEST':
      return {
        ...state,
        __metadata: {}
      };
    case 'ring/config/GET_LANGUAGE_SUCCESS':
      return {
        ...state,
        languages: action.payload,
        __metadata: {}
      };
    case 'ring/config/GET_LANGUAGE_FAILURE':
      return {
        ...state,
        __metadata: {
          lastError: action.payload
        }
      };
    default:
      return state;
  }
};

const bulletinsReducer = (
  state: ConfigStateT = initialState,
  action: BulletinsActionT
): ConfigStateT => {
  switch (action.type) {
    case 'ring/config/GET_BULLETINS_REQUEST':
    case 'ring/config/UPDATE_BULLETIN_REQUEST':
      return {
        ...state,
        __metadata: {}
      };
    case 'ring/config/GET_BULLETINS_SUCCESS':
      return {
        ...state,
        bulletins: action.payload,
        priorityBulletins: (function() {
          const envs = {};
          action.payload.forEach(b => {
            (b.priorityForEnvironments || []).forEach(e => {
              envs[e] = b;
            });
          });
          return envs;
        })(),
        __metadata: {}
      };
    case 'ring/config/UPDATE_BULLETIN_SUCCESS':
      return {
        ...state,
        bulletins: state.bulletins.map(b =>
          action.payload && b.id === action.payload.id
            ? {
                ...b,
                messageFi: action.payload.messageFi,
                messageEn: action.payload.messageEn,
                messageSv: action.payload.messageSv,
                activationDate: action.payload.activationDate
              }
            : b
        ),
        __metadata: {}
      };
    case 'ring/config/CREATE_BULLETIN_SUCCESS':
      return {
        ...state,
        bulletins: [...state.bulletins, action.payload],
        __metadata: {}
      };
    case 'ring/config/DELETE_BULLETIN_SUCCESS':
      return {
        ...state,
        bulletins: state.bulletins.filter(b => b.id !== action.payload),
        __metadata: {}
      };
    case 'ring/config/GET_BULLETINS_FAILURE':
    case 'ring/config/UPDATE_BULLETIN_FAILURE':
      return {
        ...state,
        __metadata: {
          lastError: action.payload
        }
      };
    default:
      return state;
  }
};

const enterpriseSettingsReducer = (
  state: ConfigStateT = initialState,
  action: EnterpriseSettingsActionT
): ConfigStateT => {
  switch (action.type) {
    case 'ring/config/GET_ENTERPRISE_SETTINGS_REQUEST':
    case 'ring/config/UPDATE_ENTERPRISE_SETTINGS_REQUEST':
      return {
        ...state,
        __metadata: {}
      };
    case 'ring/config/GET_ENTERPRISE_SETTINGS_SUCCESS':
    case 'ring/config/UPDATE_ENTERPRISE_SETTINGS_SUCCESS':
      return action.payload.enterpriseId
        ? {
            ...state,
            enterpriseSettings: {
              ...state.enterpriseSettings,
              [action.payload.enterpriseId]: {
                ...action.payload
              }
            },
            __metadata: {}
          }
        : state;
    case 'ring/config/GET_ENTERPRISE_SETTINGS_FAILURE':
    case 'ring/config/UPDATE_ENTERPRISE_SETTINGS_FAILURE':
      return {
        ...state,
        __metadata: {
          lastError: action.payload
        }
      };
    default:
      return state;
  }
};

const userConfigReducer = (
  state: ConfigStateT = initialState,
  action: UserConfigActionT
): ConfigStateT => {
  switch (action.type) {
    case 'ring/config/GET_USER_CONFIG_REQUEST':
    case 'ring/config/UPDATE_USER_CONFIG_REQUEST':
    case 'ring/config/REMOVE_FAVOURITE_REQUEST':
      return {
        ...state,
        __metadata: {}
      };
    case 'ring/config/GET_USER_CONFIG_SUCCESS':
    case 'ring/config/UPDATE_USER_CONFIG_SUCCESS':
    case 'ring/config/REMOVE_FAVOURITE_SUCCESS':
      return {
        ...state,
        userConfig: action.payload,
        __metadata: {}
      };
    case 'ring/config/GET_USER_CONFIG_FAILURE':
    case 'ring/config/UPDATE_USER_CONFIG_FAILURE':
    case 'ring/config/REMOVE_FAVOURITE_FAILURE':
      return {
        ...state,
        __metadata: {
          lastError: action.payload
        }
      };
    default:
      return state;
  }
};

const rootReducer = (state: ConfigStateT = initialState, action: ActionT): ConfigStateT => {
  switch (action.type) {
    case 'ring/config/GET_LANGUAGE_REQUEST':
    case 'ring/config/GET_LANGUAGE_SUCCESS':
    case 'ring/config/GET_LANGUAGE_FAILURE': {
      return configReducer.languageReducer(state, action);
    }
    case 'ring/config/GET_USER_CONFIG_REQUEST':
    case 'ring/config/GET_USER_CONFIG_SUCCESS':
    case 'ring/config/GET_USER_CONFIG_FAILURE':
    case 'ring/config/UPDATE_USER_CONFIG_REQUEST':
    case 'ring/config/UPDATE_USER_CONFIG_SUCCESS':
    case 'ring/config/UPDATE_USER_CONFIG_FAILURE':
    case 'ring/config/REMOVE_FAVOURITE_REQUEST':
    case 'ring/config/REMOVE_FAVOURITE_SUCCESS':
    case 'ring/config/REMOVE_FAVOURITE_FAILURE': {
      return configReducer.userConfigReducer(state, action);
    }
    case 'ring/config/CREATE_BULLETIN_REQUEST':
    case 'ring/config/CREATE_BULLETIN_SUCCESS':
    case 'ring/config/CREATE_BULLETIN_FAILURE':
    case 'ring/config/DELETE_BULLETIN_REQUEST':
    case 'ring/config/DELETE_BULLETIN_SUCCESS':
    case 'ring/config/DELETE_BULLETIN_FAILURE':
    case 'ring/config/GET_BULLETINS_REQUEST':
    case 'ring/config/GET_BULLETINS_SUCCESS':
    case 'ring/config/GET_BULLETINS_FAILURE':
    case 'ring/config/UPDATE_BULLETIN_REQUEST':
    case 'ring/config/UPDATE_BULLETIN_SUCCESS':
    case 'ring/config/UPDATE_BULLETIN_FAILURE': {
      return configReducer.bulletinsReducer(state, action);
    }
    case 'ring/config/GET_ENTERPRISE_SETTINGS_REQUEST':
    case 'ring/config/GET_ENTERPRISE_SETTINGS_SUCCESS':
    case 'ring/config/GET_ENTERPRISE_SETTINGS_FAILURE':
    case 'ring/config/UPDATE_ENTERPRISE_SETTINGS_REQUEST':
    case 'ring/config/UPDATE_ENTERPRISE_SETTINGS_SUCCESS':
    case 'ring/config/UPDATE_ENTERPRISE_SETTINGS_FAILURE': {
      return configReducer.enterpriseSettingsReducer(state, action);
    }
    default:
      return state;
  }
};

const configReducer = {
  initialState,
  languageReducer,
  userConfigReducer,
  bulletinsReducer,
  enterpriseSettingsReducer,
  rootReducer
};

export default configReducer;
