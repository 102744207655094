/* eslint-disable flowtype/no-weak-types */
// @flow

import React, { type Element } from 'react';
import { Button } from '@design-system/component-library';
import { useTranslation } from 'react-i18next';
import Dismiss from '../../../components/Button/Dismiss';
import 'react-time-picker/dist/TimePicker.css';
import styles from './AddHolidaysDialog.module.scss';

export type PropsT = {
  onClose: (*) => *,
  enterpriseId: string,
  usedInCalendars: string[]
};

const UsageDialog = ({ onClose, enterpriseId, usedInCalendars }: PropsT): Element<'div'> => {
  const { t } = useTranslation();

  return (
    <div
      className={`ea-modal ea-modal--open styleguide-dialog-position ${styles.container}`}
      role="dialog"
      aria-modal="true"
    >
      <div className="ea-modal__overlay" />
      <div className={`ea-modal__content ${styles.content}`}>
        {onClose && <Dismiss id="close-button" onClose={onClose} />}
        <div className={styles.box}>
          <h2 id="dialog-title" className="ea-h3 ea-h3--thick">
            {t('calendars.templates.usageDialog.title')}
          </h2>
          <div className={styles.infotext}>{t('calendars.templates.usageDialog.description')}</div>
          {usedInCalendars.map(calendarId => (
            <a
              href={`/enterprises/${enterpriseId}/callflows/calendars/${calendarId}`}
              id={`calendar-link-${calendarId}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              {calendarId}
            </a>
          ))}
          <div className={styles.infotext}>{t('calendars.templates.usageDialog.help')}</div>
          <div className={styles.buttons}>
            <Button size="l" onClick={onClose}>
              {t('calendars.templates.dialogActions.close')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsageDialog;
