// @flow

import React, { type Element } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WelcomeAnnouncementIcon } from '../../../../../assets/callflow/details/welcome-announcement-small.svg';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import CallflowProperty from '../../../components/view/children/CallflowProperty';
import CallflowAudioProperty from '../../../components/view/children/CallflowAudioProperty';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';

export type PropsT = {|
  callflowId: string
|};

export const NotificationDetails = (props: PropsT): Element<typeof CallflowDetails> | null => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  if (!acdData) {
    return null;
  }

  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  const acdIvrCallCenter = R.path(['ivrProperties', 'acdCallCenter'], acdData) || {};

  const callflowAudioFieldConverter = new AudioFieldConverter(
    acdData.enterpriseId,
    acdData.id,
    'acds'
  );

  const active = acdCallCenter ? !acdCallCenter.skipWelcomeMsg : false;

  return (
    <CallflowDetails
      enterpriseId={acdData.enterpriseId}
      callflowId={acdData.id}
      elementType={acdData.type}
      nodeId={`acd_message_${acdData.id}`}
      nodeType="ACD_MESSAGE"
      icon={<WelcomeAnnouncementIcon />}
      title={t('callflows.viewAcdNotificationDetails.title')}
      active={active}
    >
      <CallflowDetailsColumn id="notifications-column-1">
        {((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') ? (
          <CallflowProperty
            id="initialRingbackTone"
            title={t('callflows.viewAcdNotificationDetails.initialRingbackTone')}
            property={acdCallCenter.initialRingbackTone}
            forceActive
            basicValue
          />
        ) : null}

        <CallflowAudioProperty
          title={t('callflows.viewAcdNotificationDetails.welcomeMsg')}
          audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
            'WelcomeMsg',
            R.path(['audios', 'welcomeMsg', 'extensionAudio', 'name'], acdCallCenter),
            R.path(['welcomeMsg'], acdIvrCallCenter)
          )}
          isActive={active}
        />

        <CallflowProperty
          id="bypassWelcome"
          title={t('callflows.viewAcdNotificationDetails.bypassWelcome')}
          booleanInUseMsg={t('callflows.viewAcdNotificationDetails.bypassWelcomeTrue')}
          booleanNotInUseMsg={t('callflows.viewAcdNotificationDetails.bypassWelcomeFalse')}
          property={acdCallCenter.bypassWelcome}
          active={active}
          basicValue
        />

        {((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') ? (
          <div>
            <CallflowProperty
              id="keepWaitingMusicWhenBypassWelcome"
              title={t('callflows.viewAcdNotificationDetails.keepWaitingMusicWhenBypassWelcome')}
              property={acdCallCenter.keepWaitingMusicWhenBypassWelcome}
              active={active}
              basicValue
            />
            <CallflowAudioProperty
              title={t('callflows.viewAcdNotificationDetails.ringMsg')}
              audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
                'RingMsg',
                R.path(['audios', 'ringMsg', 'extensionAudio', 'name'], acdCallCenter),
                R.path(['ringMsg'], acdIvrCallCenter)
              )}
              isActive={active}
            />

            {/* TODO: Uncomment when waitingAnnounceMsg is implemented
            <CallflowAudioProperty
              title={t('callflows.viewAcdNotificationDetails.waitingAnnounceMsg')}
              audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
                'WaitingAnnounceMsg',
                R.path(['audios', 'waitingAnnounceMsg', 'extensionAudio', 'name'], acdCallCenter),
                R.path(['waitingAnnounceMsg'], acdIvrCallCenter)
              )}
              isActive={active}
            />
            */}
          </div>
        ) : null}
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default NotificationDetails;
