// @flow strict-local

import React, { type Element, useEffect, useState } from 'react';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import type { AcdEntityT, OverflowGroupT } from '../../../../../ducks/entities/acd/acdTypes';
import type {
  GroupMemberT,
  RingPatternT
} from '../../../../../ducks/entities/callFlow/commonCallFlowTypes';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import CallDistributionSelector from '../../../components/edit/children/CallDistributionSelector';
import { createPatchPayload } from '../../../components/edit/CallflowPropertyUtils';
import UserSelector from '../../../components/edit/children/UserSelector';
import IntegerField from '../../../components/edit/children/IntegerField';
import { ReactComponent as AgentsIcon } from '../../../../../assets/callflow/details/agents-small.svg';
import { getIntegerFieldLimits } from '../../../../../utils/validationUtils';
import useEnterprise from '../../../../useEnterprise';
import { update } from '../../../../../ducks/entities/acd/acdOperations';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';
import ToggleField from '../../../components/edit/children/ToggleField';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';

export type PropsT = {|
  callflowId: string
|};

type FormT = {
  groupMembers: GroupMemberT[],
  ringPattern: RingPatternT,
  ringingTimeout: number,
  syndicalPause: number,
  timeUnreachable: number,
  preferLastOperator: boolean,
  preferLastOperatorRetention: number
};

let patchCancelTokenSource: CancelTokenSource;
// $FlowFixMe: TODO: fix
export const acdAgentSchema = yup.object().shape({
  ringingTimeout: yup
    .number()
    .integer()
    .min(10)
    .max(115)
    .default(27)
    .required(),
  syndicalPause: yup
    .number()
    .integer()
    .min(0)
    .max(600)
    .default(60)
    .required(),
  timeUnreachable: yup
    .number()
    .integer()
    .min(0)
    .max(600)
    .default(60)
    .required(),
  preferLastOperatorRetention: yup
    .number()
    .integer()
    .min(0)
    .max(9999)
    .default(24)
    .required()
});

export const removeOverflowGroupIfNeeded = (
  ringPattern: RingPatternT,
  overflowGroup: ?OverflowGroupT
) => ringPattern === 'unisson' && overflowGroup && { overflowGroupId: null };

export const EditAcdAgent = (props: PropsT): Element<typeof EditCallflowDetails> | null => {
  const { callflowId } = props;

  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const { enterprise } = useEnterprise(acdData.enterpriseId);
  const [periodicStatsResetMoments, setPeriodicStatsResetMoments] = useState('');

  const getPeriodicStatsResetMoments = async () => {
    const url = `/api/v1/enterprises/${acdData.enterpriseId}/services/ivrs`;
    const params = { ivrName: 'EnterpriseConfiguration' };
    const response = axios({
      method: 'GET',
      url,
      params
    });
    const { data } = await response;

    const enterpriseConfiguration = data?.results?.[0]?.ivrProperties?.enterpriseConfiguration;

    if (enterpriseConfiguration && enterpriseConfiguration.periodicStatsResetMoments) {
      setPeriodicStatsResetMoments(enterpriseConfiguration.periodicStatsResetMoments.value);
    }
  };

  useEffect(() => {
    patchCancelTokenSource = CancelToken.source();
    getPeriodicStatsResetMoments();
    return () => {
      patchCancelTokenSource.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!acdData) {
    return null;
  }

  const initialProperties = {
    preferLastOperator:
      R.path(['servicesSettings', 'acdCallCenter', 'preferLastOperator'], acdData) || false,
    preferLastOperatorRetention:
      R.path(['servicesSettings', 'acdCallCenter', 'preferLastOperatorRetention'], acdData) || 0,
    ringingTimeout: R.path(['servicesSettings', 'acdCallCenter', 'ringingTimeout'], acdData) || 0,
    timeUnreachable: R.path(['servicesSettings', 'acdCallCenter', 'timeUnreachable'], acdData) || 0
  };

  const initialFormValues: FormT = {
    ...initialProperties,
    ringPattern: acdData.ringPattern,
    syndicalPause: acdData.syndicalPause,
    groupMembers: acdData && acdData.members ? acdData.members : []
  };

  // update
  const onSubmit = async (formData: FormT): Promise<AcdEntityT> => {
    const patchPayload = createPatchPayload(
      {
        syndicalPause: formData.syndicalPause,
        ringPattern: formData.ringPattern,
        memberIds: formData.groupMembers.map(user => user.id),
        ...removeOverflowGroupIfNeeded(formData.ringPattern, acdData.overflowGroup)
      },
      formData,
      Object.keys(initialProperties).filter(
        key =>
          ((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') ||
          (key !== 'preferLastOperator' && key !== 'preferLastOperatorRetention')
      )
    );
    return dispatch(
      update(
        acdData.enterpriseId,
        acdData.type,
        acdData.id,
        patchPayload,
        patchCancelTokenSource.token,
        createCsrfHeader(currentUser)
      )
    );
  };

  const maxGroupSizesByPatterns = {
    cyclic: (enterprise || {}).maxMembersInExtensionGroup || 50,
    unisson: (enterprise || {}).maxMembersInUnissonGroup || 8,
    sequential: (enterprise || {}).maxMembersInExtensionGroup || 50,
    oldestIdle: (enterprise || {}).maxMembersInExtensionGroup || 50,
    pickUp: (enterprise || {}).maxMembersInExtensionGroup || 50,
    leastTalking: (enterprise || {}).maxMembersInExtensionGroup || 50,
    leastCalls: (enterprise || {}).maxMembersInExtensionGroup || 50
  };

  return (
    <EditCallflowDetails
      nodeId={acdData.id}
      icon={<AgentsIcon />}
      title={t('callflows.editAcdAgent.title')}
      description={t('callflows.editAcdAgent.description')}
      defaultValues={initialFormValues}
      validationSchema={acdAgentSchema}
      onSaveForm={onSubmit}
    >
      <CallDistributionSelector
        field="ringPattern"
        memberCountFn={(formValues: FormT) => (formValues.groupMembers || []).length}
        unisonMaxMembers={maxGroupSizesByPatterns.unisson}
        overflowGroupEnabled={!!acdData.overflowGroup}
        isACD
        periodicStatsResetMoments={periodicStatsResetMoments}
      />

      <UserSelector
        field="groupMembers"
        enterpriseId={acdData.enterpriseId}
        title={t('callflows.editAcdAgent.members.title')}
        description={t('callflows.editAcdAgent.members.description')}
        maxSizesPatterns={maxGroupSizesByPatterns}
      />
      {((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') ? (
        <div>
          <ToggleField
            field="preferLastOperator"
            elementId="preferLastOperator"
            label={t('callflows.editAcdAgent.preferLastOperator')}
            tooltip={t('callflows.viewAcdAgentDetails.preferLastOperatorTooltip')}
          />
          <IntegerField
            field="preferLastOperatorRetention"
            title={t('callflows.editAcdAgent.preferLastOperatorRetentionTitle')}
            errorMessage={t(
              'integerField.error',
              // $FlowFixMe: TODO: fix
              getIntegerFieldLimits(acdAgentSchema.fields.preferLastOperatorRetention)
            )}
            postFixDescription={t('callflows.editAcdAgent.preferLastOperatorRetentionPostMsg')}
            maxLength={4}
            disabledFn={(formData: FormT) => !formData.preferLastOperator}
          />
        </div>
      ) : null}
      <IntegerField
        field="ringingTimeout"
        title={t('callflows.editAcdAgent.ringingTimeoutTitle')}
        inputDescription={t('callflows.editAcdAgent.ringingTimeoutDescription')}
        tooltip={t(
          'callflows.viewAcdAgentDetails.ringingTimeoutTooltip',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdAgentSchema.fields.ringingTimeout)
        )}
        errorMessage={t(
          'integerField.error',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdAgentSchema.fields.ringingTimeout)
        )}
      />

      <IntegerField
        field="syndicalPause"
        title={t('callflows.editAcdAgent.syndicalPauseTitle')}
        inputDescription={t('callflows.editAcdAgent.syndicalPauseDescription')}
        tooltip={t(
          'callflows.viewAcdAgentDetails.syndicalPauseTooltip',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdAgentSchema.fields.syndicalPause)
        )}
        errorMessage={t(
          'integerField.error',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdAgentSchema.fields.syndicalPause)
        )}
      />

      <IntegerField
        field="timeUnreachable"
        title={t('callflows.editAcdAgent.timeUnreachableTitle')}
        inputDescription={t('callflows.editAcdAgent.timeUnreachableDescription')}
        tooltip={t(
          'callflows.viewAcdAgentDetails.timeUnreachableTooltip',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdAgentSchema.fields.timeUnreachable)
        )}
        errorMessage={t(
          'integerField.error',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdAgentSchema.fields.timeUnreachable)
        )}
      />
    </EditCallflowDetails>
  );
};

export default EditAcdAgent;
