// @flow

import * as R from 'ramda';
import React, { type Element, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { createCloseChangelogAction } from '../../ducks/ui/header/headerUiActions';
import { BaseModal } from '../../components/BaseModal';
import ActionButton from '../../components/Button/ActionButton';
import { operations } from '../../ducks/config';
import {
  getSortedAndFilteredBulletins,
  getChangelogKey,
  getNewChangelogDisplayedValue
} from '../login/LoginUtil';
import { getConfigurationValue } from '../../userConfigHelpers';
import ConfirmButton from '../../components/Button/ConfirmButton';
import { createCsrfHeader } from '../../utils/accessRightUtils';
import styles from './ChangelogModal.module.scss';

type PropsT = {||};

// eslint-disable-next-line no-unused-vars
export const ChangelogModal = (props: PropsT): Element<typeof BaseModal> | null => {
  // redux
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const changelogOpen: boolean = useSelector(state => state.ui.header.changelogOpen);
  const goToBulletinId: number = useSelector(state => state.ui.header.goToBulletinId);
  const previewMessageFi: boolean = useSelector(state => state.ui.header.previewMessageFi);
  const previewMessageEn: boolean = useSelector(state => state.ui.header.previewMessageEn);
  const previewMessageSv: boolean = useSelector(state => state.ui.header.previewMessageSv);
  const previewMessageEt: boolean = useSelector(state => state.ui.header.previewMessageEt);
  const userConfig = useSelector(state => state.config.userConfig);
  const currentUser = useSelector(state => state.currentUser);
  const bulletins = useSelector(state => state.config.bulletins);
  const activeLanguage = i18n.language;
  const [bulletinIndex, setBulletinIndex] = useState(-1);

  const isPreview = () =>
    previewMessageFi !== undefined ||
    previewMessageEn !== undefined ||
    previewMessageSv !== undefined ||
    previewMessageEt !== undefined;

  useEffect(() => {
    const fetchUserConfig = async () => {
      await dispatch(operations.getUserConfig());
      await dispatch(operations.getBulletins());
    };
    if (!isPreview()) {
      fetchUserConfig();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnClose = async (): Promise<void> => {
    setBulletinIndex(-1);
    const changelogDisplayedKey = getChangelogKey(
      `${currentUser.environment}${currentUser.country || ''}`
    );
    const currentChangelogDisplayedValue =
      userConfig && getConfigurationValue(userConfig, changelogDisplayedKey);
    const newChangelogDisplayedValue = getNewChangelogDisplayedValue(
      `${currentUser.environment}${currentUser.country || ''}`,
      currentUser.enterprises ? currentUser.enterprises.map(e => e.ownerAdmtiveDomainId) : [],
      bulletins,
      currentChangelogDisplayedValue
    );
    dispatch(
      operations.updateUserConfig(
        [
          ...R.reject(item => 'key' in item && item.key === changelogDisplayedKey, userConfig),
          { key: changelogDisplayedKey, value: newChangelogDisplayedValue }
        ],
        createCsrfHeader(currentUser)
      )
    );
    dispatch(createCloseChangelogAction());
  };

  const filteredBulletins = getSortedAndFilteredBulletins(currentUser, bulletins);

  let message = '';
  if (isPreview()) {
    if (activeLanguage === 'fi') {
      message = previewMessageFi;
    } else if (activeLanguage === 'en') {
      message = previewMessageEn;
    } else if (activeLanguage === 'sv') {
      message = previewMessageSv;
    } else if (activeLanguage === 'et') {
      message = previewMessageEt;
    }
  } else if (changelogOpen) {
    let index = bulletinIndex;

    if (index === -1) {
      if (goToBulletinId) {
        index = filteredBulletins.indexOf(
          filteredBulletins.find(b => {
            return b.id === goToBulletinId;
          })
        );
      } else {
        index = 0;
      }
      setBulletinIndex(index);
    }

    const bulletin = filteredBulletins[index];

    if (bulletin) {
      if (activeLanguage === 'fi') {
        message = bulletin.messageFi;
      } else if (activeLanguage === 'en') {
        message = bulletin.messageEn;
      } else if (activeLanguage === 'sv') {
        message = bulletin.messageSv;
      } else if (activeLanguage === 'et') {
        message = bulletin.messageEt;
      }
    }
  }

  const findHeadlineFromMessage = (): string => {
    if (!message || !(message instanceof String || typeof message === 'string')) {
      return '';
    }
    const headLineStart = message.indexOf('<h');
    const headLineEnd = message.indexOf('</h') + 5;
    if (headLineStart >= 0 && headLineEnd > headLineStart) {
      const foundHeadline = message.substring(headLineStart, headLineEnd).trim();
      message = message.replace(foundHeadline, '');
      return foundHeadline;
    }
    return '';
  };

  const headline = findHeadlineFromMessage();

  return changelogOpen ? (
    <BaseModal modalStyles={[styles.modal]} onClose={() => handleOnClose()}>
      <div>
        <div className={styles.header}>{headline ? parse(headline) : ''}</div>
        <div className={styles['changelog-container']}>
          <div className={styles['text-container']}>
            <div className={styles['text-content']}>{message ? parse(message) : ''}</div>
          </div>
          <div className={styles['bottom-area']}>
            <div>
              {!isPreview() && (
                <>
                  <ConfirmButton
                    id="changelog-previous-button"
                    className={styles['previous-button']}
                    label={t('changelog.previous')}
                    disabled={bulletinIndex === filteredBulletins.length - 1}
                    onClickAction={() => {
                      if (bulletinIndex < filteredBulletins.length - 1) {
                        setBulletinIndex(bulletinIndex + 1);
                      }
                    }}
                  />
                  <ConfirmButton
                    id="changelog-next-button"
                    className={styles['next-button']}
                    label={t('changelog.next')}
                    disabled={bulletinIndex === 0}
                    onClickAction={() => {
                      if (bulletinIndex > 0) {
                        setBulletinIndex(bulletinIndex - 1);
                      }
                    }}
                  />
                </>
              )}
            </div>
            <ActionButton
              id="close-button"
              className={styles['changelog-close-button']}
              label={t('changelog.ok')}
              onClickAction={() => handleOnClose()}
            />
          </div>
        </div>
      </div>
    </BaseModal>
  ) : null;
};

export default ChangelogModal;
