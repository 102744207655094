// @flow

import React, { type Element, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CancelToken } from 'axios';
import queryString from 'querystring';
import { useTranslation } from 'react-i18next';
import LoadingView from '../callFlows/callFlowGrid/gridElement/content/LoadingView';
import { getGotoDestination, getLoginConfigs, isUserLanguageValid } from './LoginUtil';
import { fetchCsrf, login } from '../../ducks/currentUser/currentUserOperations';
import { getEnterprise } from '../../ducks/entities/enterprise/enterpriseOperations';
import { createSelectEnterpriseAction } from '../../ducks/currentUser/currentUserActions';
import { operations } from '../../ducks/config';
import {
  createOpenChangelogAction,
  createOpenOnboardingAction
} from '../../ducks/ui/header/headerUiActions';
import {
  goTo,
  goToCallFlowServices,
  goToEnterpriseUsers,
  goToLandingPage
} from '../../navigationOperations';
import type { UserConfigT } from '../../ducks/config';
import type { CurrentUserT } from '../../ducks/currentUser/currentUserTypes';
import {
  isAcdManager,
  isMultiEnterpriseAdmin,
  isSingleEnterpriseAdmin,
  isSwitchboardUser
} from '../../utils/accessRightUtils';
import configOperations from '../../ducks/config/configOperations';
import styles from './LoginBase.module.scss';

export const TransferMyIstraLogin = (): Element<'div'> => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const forwardUserIfLoggedIn = (currentUser: CurrentUserT) => {
    const getEnterpriseFromCurrentUser = () =>
      currentUser.enterprises && currentUser.enterprises[0] ? currentUser.enterprises[0] : null;
    const { redirect } = queryString.parse(window.location.search.slice(1));

    if (redirect) {
      goTo(redirect);
    } else if (isSingleEnterpriseAdmin(currentUser) || isSwitchboardUser(currentUser)) {
      const enterprise = getEnterpriseFromCurrentUser();
      if (enterprise) {
        dispatch(goToEnterpriseUsers(enterprise.id));
      }
    } else if (isMultiEnterpriseAdmin(currentUser)) {
      dispatch(goToLandingPage());
    } else if (isAcdManager(currentUser)) {
      const enterprise = getEnterpriseFromCurrentUser();
      if (enterprise) {
        dispatch(goToCallFlowServices(enterprise.id));
      }
    }
  };

  const loginOmaRingWithMyIstraSession = async () => {
    const { istraURL, env } = queryString.parse(window.location.search.slice(1));
    const username = `elisa,${istraURL},${env}`;
    const password = '';
    const currentUser: CurrentUserT = await dispatch(login(username, password));
    const destination = getGotoDestination(currentUser);

    if (destination) {
      dispatch(goTo(destination));
      return;
    }

    const csrfToken = await dispatch(fetchCsrf());

    if (!currentUser.multiEnterpriseAdmin) {
      const enterprise =
        currentUser.enterprises && currentUser.enterprises[0]
          ? await dispatch(getEnterprise(currentUser.enterprises[0].id, CancelToken.source().token))
          : null;
      if (enterprise) {
        dispatch(createSelectEnterpriseAction(enterprise.entID, enterprise.fullName));
      }
    }
    const userConfig: UserConfigT = await dispatch(operations.getUserConfig());
    const bulletins = await dispatch(configOperations.getBulletins());
    const { modifiedUserConfig, shouldOpenOnboarding, shouldOpenChangelog } = getLoginConfigs(
      `${currentUser.environment}${currentUser.country || ''}`,
      currentUser.enterprises?.map(enterprise => enterprise.ownerAdmtiveDomainId || '') || [],
      userConfig,
      bulletins
    );
    if (modifiedUserConfig) {
      await dispatch(
        operations.updateUserConfig(modifiedUserConfig, {
          'X-CSRF-TOKEN': csrfToken
        })
      );
    }
    if (shouldOpenOnboarding) {
      dispatch(createOpenOnboardingAction());
    } else if (shouldOpenChangelog) {
      dispatch(createOpenChangelogAction());
    }
    forwardUserIfLoggedIn(currentUser);
    if (currentUser.appLanguage && isUserLanguageValid(currentUser.appLanguage)) {
      i18n.changeLanguage(currentUser.appLanguage);
    }
  };

  useEffect(() => {
    loginOmaRingWithMyIstraSession();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles['loading-view']}>
      <LoadingView />
    </div>
  );
};

export default TransferMyIstraLogin;
